import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LayoutComponent} from "./layout.component";
import {AtalhoComponent} from "./atalho/atalho.component";
import {HomeComponent} from "./home/home.component";
import {AuthGuard} from "../_helpers";
import {NotificacaoComponent} from "./notificacao/notificacao.component";
import {SegurancaComponent} from "./seguranca/seguranca.component";
import { PermissoesComponent } from './permissoes/permissoes.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { PerfilSistemaGrupoAdComponent } from './perfil-sistema-grupo-ad/perfil-sistema-grupo-ad.component';
import { ParametroComponent } from './parametro/parametro.component';
import { ParametroSistemaComponent } from './parametro-sistema/parametro-sistema.component';

const routes: Routes = [
  {
    path: '', component: LayoutComponent,
    children: [
      { path: '', component: HomeComponent},
      { path: 'atalho', component: AtalhoComponent, canActivate: [AuthGuard], data: { uf: '', funcionalidade: '' } },
      { path: 'notificacao', component: NotificacaoComponent, canActivate: [AuthGuard], data: { uf: '', funcionalidade: '' } },
      { path: 'seguranca', component: SegurancaComponent, canActivate: [AuthGuard], data: { uf: '', funcionalidade: '' } },
      { path: 'permissoes', component: PermissoesComponent, canActivate: [AuthGuard],data: { uf: '', funcionalidade: '' } },
      { path: 'parametros', component: ParametroComponent, canActivate: [AuthGuard]},
      { path: 'parametroSistema', component: ParametroSistemaComponent, canActivate: [AuthGuard]},
      { path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuard] },
      { path: 'perfilsistemagrupoad', component: PerfilSistemaGrupoAdComponent, canActivate: [AuthGuard]},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministracaoRoutingModule { }
