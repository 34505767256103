import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BaseResponse, DataSourceResponse } from '../_models';
import { environment } from 'src/environments/environment';
import { Sistema } from '../_models/sistema';
import { DataSourceRequest } from '../_models/data-source-request';
import HttpClientUtils from '../_helpers/http-client-utils';
import {Perfil} from "../_models/perfil";

@Injectable({providedIn: 'root'})
export class PerfilService {
  constructor(private http: HttpClient) {}

  listarSistemas(){
    return this.http.get<BaseResponse<any>>(`${environment.apiUrl}/sistema/listar`);
  }

  //HUBHAWK
  ativarPerfis(uuids){
    return this.http.put<BaseResponse<Perfil>>(`${environment.apiUrl}/perfil/ativar`, uuids);
  }
  suspenderPerfis(uuids){
    return this.http.put<BaseResponse<Perfil>>(`${environment.apiUrl}/perfil/suspender`, uuids);
  }
  cancelarPerfis(uuids){
    return this.http.put<BaseResponse<Perfil>>(`${environment.apiUrl}/perfil/cancelar`, uuids);
  }
  salvarPerfil(perfilDto){
    return this.http.post<BaseResponse<Perfil>>(`${environment.apiUrl}/perfil/salvar`,perfilDto);
  }
  excluirPerfil(id){
    return this.http.delete<BaseResponse<any>>(`${environment.apiUrl}/perfil/${id}`);
  }
  listarPerfisPorUsuario(login){
    return this.http.get<BaseResponse<any>>(`${environment.apiUrl}/perfil/listar/usuario/${login}`);
  }
  //HUBHAWK
}
