import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from '../../environments/environment';
import {BaseResponse} from "../_models";
import { Notificacao} from "../_models/notificacao";

@Injectable({providedIn: 'root'})
export class NotificacaoService {

  constructor(private http: HttpClient) {
  }

  incluir(form) {
    let dto = this.montarDto(form);
    return this.http.post<BaseResponse<any>>(`${environment.apiUrl}/notificacao`, dto);
  }

  excluir(idNotificacao: number) {
    return this.http.delete<BaseResponse<any>>(`${environment.apiUrl}/notificacao/${idNotificacao}`);
  }

  private montarDto(form) {
    let dto = new Notificacao();
    dto.titulo = form.titulo;
    dto.texto = form.texto;
    dto.categoria = parseInt(form.categoria);

    return dto;
  }
}
