import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { RedefinicaoSenha, BaseResponse, DataSourceResponse} from '../_models';

@Injectable({ providedIn: 'root' })
export class RedefinicaoSenhaService {

  constructor(
    private router: Router,
    private http: HttpClient,
  ) {
  }

  getByToken(token: string) {
    return this.http.get<BaseResponse<DataSourceResponse<RedefinicaoSenha>>>(`${environment.apiUrl}/redefinicaoSenha?token=${token}`);
    }

  update(token, params) {
    return this.http.post(`${environment.apiUrl}/redefinicaosenha?token=${token}`, params)
      .pipe(map(x => {
        return x;
      }));
  }

}
