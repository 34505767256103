<div class="container-fluid">
  <div class="row justify-content-between no-gutters">
    <div class="col-5"><h2>{{uf.nome}}</h2></div>
    <div class="col-2" class="flexEnd">
      <button pButton pRipple label="Salvar" (click)="salvarAlteracoes()" icon="pi pi-save" class="p-button p-button-success"
              *ngIf="permissionService.hasPermission(uf.codigo,'Atualizar')"></button>
    </div>  
  </div>
  <br>
  <form [formGroup]="formParametroSistema">
    <div class="row">
      <input id="float-input" pInputText type="text" class="form-control" formControlName="idParametroSistema" hidden="hidden">
      <div class="col-4">
        <span class="p-float-label">
          <p-dropdown class="dropDownSistemas" (onChange)="selecionarSistema()" [options]="sistemas" [(ngModel)]="sistemaSelecionado"
                      formControlName="codigoSistema" optionLabel="nome" optionValue="codigo"
                      [autoDisplayFirst]="false"></p-dropdown>
          <label for="float-input">Sistema</label>
        </span>
      </div>
      <div class="col-8">
        <span class="p-float-label">
            <input id="float-input" pInputText type="text" class="form-control" formControlName="perfilInicial">
            <label for="float-input">Perfil Inicial</label>
        </span>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-4">
        <span class="p-float-label">
            <input id="float-input" pInputText type="text" class="form-control" formControlName="servidorAd">
            <label for="float-input">Servidor Active Directory</label>
        </span>
      </div>
      <div class="col-4">
        <span class="p-float-label">
            <input id="float-input" pInputText type="text" class="form-control" formControlName="dominioAd">
            <label for="float-input">Dominio Active Directory</label>
        </span>
      </div>
      <div class="col-4">
        <span class="p-float-label">
            <input id="float-input" pInputText type="text" class="form-control" formControlName="grupoAd">
            <label for="float-input">Grupo Active Directory</label>
        </span>
      </div>
    </div>
  </form>
</div>
