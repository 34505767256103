import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-semacesso',
  templateUrl: './semacesso.component.html'
})
export class SemacessoComponent implements OnInit {
  uf: string;
  funcionalidade: string;

  constructor(private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.route.params
    this.route.queryParams.subscribe(params => {
      this.uf = params['uf'];
      this.funcionalidade = params['funcionalidade'];
    });
  }

}
