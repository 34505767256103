export enum SituacaoCliente {
  Ativo = 1,
  Suspenso = 2,
  Cancelado = 3
}
export const SituacaoClienteMapping = [
  { value: SituacaoCliente.Ativo, nome: "Ativo"},
  { value: SituacaoCliente.Suspenso, nome: "Suspenso"},
  { value: SituacaoCliente.Cancelado, nome: "Cancelado"}
]

export const SituacaoClienteList = Object.keys(SituacaoCliente)
  .filter((v) => isNaN(Number(v)))
  .map((name) => {
    return {
      name,
      code: SituacaoCliente[name as keyof typeof SituacaoCliente]
    };
  });
