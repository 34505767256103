import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataSourceRequest } from 'src/app/_models/data-source-request';
import { TipoSituacao, TipoSituacaoMapping } from 'src/app/_models/enums/situacao';
import { FiltroClienteDto } from 'src/app/_models/filtros/filtro-cliente-dto';
import { Usuario } from 'src/app/_models/usuario';
import { AlertService, PermissionService } from 'src/app/_services';
import { ClienteService } from 'src/app/_services/ClienteService';
import { UsuariosService } from 'src/app/_services/usuarios.service';


@Component({
  selector: 'app-incluir-usuario',
  templateUrl: './incluir-usuario.component.html',
  styleUrls: ['./incluir-usuario.component.css']
})
export class IncluirUsuarioComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
              public permissionService: PermissionService,
              public clienteService: ClienteService,
              private usuarioService: UsuariosService,  
              private router: Router,
              private alertService: AlertService) { }

  //enum para dropdown
  situacoes = TipoSituacaoMapping;

  //formulario Adição e Edição
  formUsuario: UntypedFormGroup;

  //array com lista de Clientes p/ dropdown
  clientes;
  
  ngOnInit(): void {
    this.createFormUsuario(new Usuario());
    this.getListaClientes();
  }

  getListaClientes() {
    var filtroDto = new FiltroClienteDto;
    filtroDto.request = new DataSourceRequest;
    this.clienteService.listarClientes(filtroDto).subscribe(
      success => {
        this.clientes = success.records;
      }
    )
  }

  createFormUsuario(usuario: Usuario) {
    this.formUsuario = this.formBuilder.group({
      uuid: [usuario.uuid],
      nome: [usuario.nome],
      email: [usuario.email],
      telefone: [usuario.telefone],
      salt: [usuario.salt],
      situacao: [TipoSituacao.Ativo],
      cliente: [usuario.cliente]
    });
    this.formUsuario.get('situacao')?.disable();
  }

  checkInputTelefone(){
    var telefone = this.formUsuario.controls['telefone'].value != null ? this.formUsuario.controls['telefone'].value.replace(/[^a-zA-Z0-9]/g, '') : ' ';
    return telefone.length < 10 ? true : false;
  }

  addUsuario(){   
    this.alertService.showProgressBar(); 
    this.usuarioService.incluirUsuario(this.formUsuario.value).subscribe(
      success => {
        this.alertService.hideProgressBar();
        this.alertService.success(success.messages[0]);
        setTimeout(() => {
          this.router.navigate(['/usuarios'])
        }, 4000);
      },
      error => {
        this.alertService.hideProgressBar();
        this.alertService.error(error);
      }
    )
  }

  voltar(){
    this.router.navigate(['/usuarios']);
  }

}
