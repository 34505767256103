<div class="container container-cards-atalhos">
  <div class="flex-grow-2 top-space"></div>
  <div class="colaborador-page-title on-card-hover">Escolha a plataforma que deseja acessar:</div>
  <div class="flex-grow-1 mid-space"></div>
  <div *ngIf="atalhos; else loadingSpinner" class="px-3 w-100">
    <div id="atalhos-container" class="atalhos-container row no-gutters on-card-hover">
      <div *ngFor="let atalho of atalhos" class="col-xl-3 col-lg-4 col-md-6">
        <a [attr.href]="atalho.url ? atalho.url : null" target="_blank" class="atalho-card mx-auto"
           (mouseenter)="atalhoHover(atalho.descricao)" (mouseleave)="atalhoLeave()">
          <img height="40" width="40" [src]="dom.bypassSecurityTrustUrl('data:image/svg+xml;base64,'.concat(atalho.icone))" alt="" class="mr-2">
          <span class="title ml-2">{{ atalho.nome }}</span>
        </a>
      </div>
    </div>
  </div>
  <ng-template #loadingSpinner>
    <div class="spinner-border thin" style="width: 3rem; height: 3rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </ng-template>

  <div class="flex-grow-1 bottom-mid-space"></div>
  <div class="atalho-description-container on-card-hover">
    <div id="atalho-description" class="atalho-description on-card-hover"></div>
  </div>
  <div class="flex-grow-2 bottom-space"></div>
</div>
