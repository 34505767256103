import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseResponse, DataSourceResponse} from '../_models';
import {PerfilPermissao} from '../_models/perfilPermissao';
import {environment} from 'src/environments/environment';
import {Sistema} from '../_models/sistema';
import {BehaviorSubject} from "rxjs";
import {Usuario} from "../_models/usuario";
import {Perfil} from "../_models/perfil";
import {DataSourceRequest} from "../_models/data-source-request";
import HttpClientUtils from "../_helpers/http-client-utils";

@Injectable({providedIn: 'root'})
export class UsuariosService {

  private usuarioEdit = new BehaviorSubject<any>({});
  usuarioEDIT = this.usuarioEdit.asObservable();

  constructor(private http: HttpClient) {
  }
  
  setUsuario(usuario: Usuario) {
    this.usuarioEdit.next(usuario);
  }

  //ADAPTADO PARA O HUBHAWK
  listarUsuariosNew(nome?, email?, telefone?, situacoes?) {
    let params = new HttpParams();
    if(nome != null && nome != ''){
      params = params.append('nome', nome);
    }
    if(email != null && email != ''){
      params = params.append('email', email);
    }
    if(telefone != null && telefone != ''){
      params = params.append('telefone', telefone);
    }
    if(situacoes != null && situacoes.length > 0){
      for (let i = 0; i < situacoes.length; i++) {
        params = params.append('situacoes', situacoes[i]);
      }
    }
    return this.http.get<BaseResponse<DataSourceResponse<any>>>(`${environment.apiUrl}/usuario`, { params: params });
  }

  ativarUsuario(idUsuario){
    return this.http.put<BaseResponse<any>>(`${environment.apiUrl}/usuario/${idUsuario}/ativar`, idUsuario);
  }

  suspenderUsuario(idUsuario){
    return this.http.put<BaseResponse<any>>(`${environment.apiUrl}/usuario/${idUsuario}/suspender`, idUsuario);
  }

  cancelarUsuario(idUsuario){
    return this.http.put<BaseResponse<any>>(`${environment.apiUrl}/usuario/${idUsuario}/cancelar`, idUsuario);
  }

  incluirUsuario(usuario){
    usuario.telefone = this.removeTelefoneMask(usuario.telefone);
    return this.http.post<BaseResponse<any>>(`${environment.apiUrl}/usuario/incluir`, usuario);
  }

  alterarUsuario(usuario){
    usuario.telefone = this.removeTelefoneMask(usuario.telefone);
    return this.http.put<BaseResponse<any>>(`${environment.apiUrl}/usuario/alterar`, usuario);
  }

  removeTelefoneMask(telefone: string){
    if(telefone != null && telefone != ''){
        telefone = telefone.replace(/[^a-zA-Z0-9]/g, '');
    }
    return telefone;
  }

  alterarSenha(usuario){
    return this.http.put<BaseResponse<any>>(`${environment.apiUrl}/usuario/gerarnovasenha`, usuario);
  }




  //TEIA
  listarPerfis( query) {
    return this.http.get<BaseResponse<DataSourceResponse<any>>>(`${environment.apiUrl}/perfil/listartodos?chaveBusca=${query}&situacoes=1&incluirSuspensos=false`);
  }

  listarUsuarios(query, nome) {
    return this.http.get<BaseResponse<DataSourceResponse<any>>>(`${environment.apiUrl}/usuario/listarporsistema?query=${query}&nome=${nome}`);
  }


  listarSistemas() {
    return this.http.get<BaseResponse<DataSourceResponse<Sistema>>>(`${environment.apiUrl}/sistema/listar`);
  }

  alterar(form, codigo) {
     let dto = this.montarDto(form, codigo);
    return this.http.put<BaseResponse<any>>(`${environment.apiUrl}/usuario/alterar`, dto);
  }

  private montarDto(form, codigo) {
    let dto = new Usuario();
    dto.id = form.id;
    dto.nome = form.nome;
    dto.sistema = codigo;
    dto.email = form.email;
    dto.login = form.login;

    dto.situacao= form.situacao ? form.situacao.value : 1;
    dto.perfilId = form.perfil ? form.perfil.id : 0;
    return dto;
  }

}
