export class Notificacao {
  id: string;
  titulo: string;
  texto: string;
  categoria: number = null;
  criadoEm: Date;
}

interface Categoria {
  name: string;
  code: number;
}

export const CategoriaNotificacao: Categoria[] = [
  {name: 'Geral', code: 1},
  {name: 'Lembrete', code: 2},
  {name: 'Novidades', code: 3},
  {name: 'Novo atalho', code: 4},
  {name: 'Nova versão', code: 5}
]
