import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Tooltip} from 'primeng/tooltip';
import {PerfilService} from 'src/app/_services/perfil.service';
import {User} from '../../_models';
import {AccountService, ModalService, PermissionService} from '../../_services';
import {MinhaNotificacaoService} from '../../_services/minhanotificacao.service';
import {MenuItem} from "primeng/api";
import {AtalhoService} from "../../_services/atalho.service";
import {AtalhoComponent} from "../../administracao/atalho/atalho.component";
import {NotificacaoComponent} from "../../administracao/notificacao/notificacao.component";
import { SlideMenu } from 'primeng/slidemenu';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})


export class HeaderComponent implements OnInit {
  //usuario em contexto
  user: User;

  //controle de seleção de sistema via modal
  sistemaSelecionado;

  //array para dropdown de sistemas
  sistemas: any[];

  //visibilidade do modal
  modalSistema = false;
  
  //controles para geração do Menu Completo
  arrayPermissoes = [];
  menuOptions = [];
  menuHeight = 140; //padrão (sem permissão nenhuma)
  alturaMenuBase = []; //altura do menu qnd abre a primera vez (somente com os elementos pai dos itens do menu) - array pra nao alocar o valor no mesmo local de memoria e nao interferir no 'menuHeight'

  //items q compõe o Menu Completo
  items: MenuItem[] = [];

  //elementos para o tooltip de informações
  tooltipText = "";
  stringEmail = "";
  stringPerfil = "";
  stringAcessoAtual = "";
  stringInicioAcessoAtual;
  date: Date = new Date();

  //seletores para trabalhar com o elemento HTML+TS
  @ViewChild(Tooltip) tooltip!: Tooltip;
  @ViewChild('tooltipId') eRef: ElementRef;
  @ViewChild(SlideMenu) slidemenu!: SlideMenu;
  @ViewChild('menuId') menuRef;

  constructor(private accountService: AccountService, private router: Router, public perfilService: PerfilService, public permissionService: PermissionService, 
              public modalService: ModalService, public atalhoService: AtalhoService, public minhaNotificacaoService: MinhaNotificacaoService) {
    this.accountService.user.subscribe(x => this.user = x);
    this.accountService.selectecSystem$.subscribe(x => this.sistemaSelecionado = x);
  }

  ngOnInit(): void {
    this.montarMenu();
    this.accountService.findLastAccessByUser(this.user.username).subscribe(
      success => {
        this.stringAcessoAtual = `#${success.data.id}`;
        this.stringInicioAcessoAtual = new Date(this.user.dateAccess).toLocaleString([], {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });
        this.perfilService.listarPerfisPorUsuario(this.user.username).subscribe(
          success => {
            this.stringEmail = success.data[0].usuario.email;
            this.montarListaPerfis(success.data);
            this.tooltipText = `E-mail: ${this.stringEmail}\n\n Perfis: ${this.stringPerfil}\n\n Acesso Atual: ${this.stringAcessoAtual}\n\n Início Acesso Atual: ${this.stringInicioAcessoAtual}`;
          }
        )
      }
    );
    this.listarSistemas();
  }

  montarListaPerfis(data) {
    data.forEach(element => {
      if (element.sistema.codigo == "HUBHAWK") {
        if (this.stringPerfil == "") {
          this.stringPerfil = this.stringPerfil + `${element.perfil.nome}`;
        } else {
          this.stringPerfil = this.stringPerfil + `- ${element.perfil.nome}`;
        }
      }
    });
  }

  //Listener para capturar o evento de click em qlqr parte da tela
  @HostListener('document:click', ['$event'])
  documentClick(event) {
    //controla a visibilidade do tooltip ao clicar fora do tooltip
    if (!this.eRef.nativeElement.contains(event.target) && this.tooltip.active) {
      this.tooltip.deactivate()
    }
    //controla a altura do conteudo do Menu ao clicar fora do menu
    if(!this.menuRef.el.nativeElement.contains(event.target)){
      setTimeout(() => {
        this.menuHeight = this.alturaMenuBase[0];
      }, 500);
    }
  }

  //Monta os nós sem pai, sendo assim o menu de Módulos de mais alta hierarquia
  montarMenu() {
    this.items.push({ label: 'Home', routerLink: '/', icon: 'pi pi-fw pi-home' });
    if(this.user.menu != undefined){
      var menuItem = this.montarMenuItem(this.user.menu.filhos);
      menuItem.forEach(element => {
        //adiciona uma altura fixa para cada item do menu
        this.menuHeight+= 49;
        this.items.push(element);
      });
    }
    this.items.push({ label: 'Sair', command: () => this.logout(), icon: 'pi pi-fw pi-power-off' });
    this.alturaMenuBase.push(this.menuHeight);
  }

  //Monta os filhos de forma recursiva (assim como 'montarMenu()'), além de atribuir a função de recalcular e atribuir a altura do menu dinamicamente
  montarMenuItem(array){
    var menuItemFinal = [];
    array.forEach(element => {
      var menuItem = {
        label: element.nome,
        routerLink: element.enderecoAcesso != null ? element.enderecoAcesso : null,
        items: this.montarMenuItem(element.filhos).length > 0 ? this.montarMenuItem(element.filhos) : null,
        command: () => { 
          //cada item do menu dispara essa função para calculo da altura do conteúdo do menu baseado no numero de filhos (itens q serão exibidos no menu)
          menuItem.items != null ? this.menuHeight = 140 + (49 * (menuItem.items.length - 1)) : this.menuHeight = this.alturaMenuBase[0];
          const menuContent = document.getElementsByClassName('p-slidemenu-content');
          menuContent[0].setAttribute('style', `height: ${this.menuHeight}px !important`);

          //além de atribuir ao botão de 'Back/Voltar' uma função q dispara tb a mesma funcionalidade q acima, de recálculo da altura do conteúdo do menu
          const menuBackButton = document.getElementsByClassName('p-slidemenu-backward');
          menuBackButton[0].addEventListener("click", () => {
            this.menuHeight = 140 + (52 * (this.user.menu.filhos.length - 1))
            const menuContentAfterBacking = document.getElementsByClassName('p-slidemenu-content');
            menuContentAfterBacking[0].setAttribute('style', `height: ${this.menuHeight}px !important`);
          })
        }
      }
      menuItemFinal.push(menuItem);
    });
    return menuItemFinal;
  }

  onClickTooltip() {
    if (this.tooltip.active) {
      this.tooltip.deactivate()
    } else {
      this.tooltip.activate();
    }
  }

  openSystemModal() {
    this.modalSistema = true;
  }

  listarSistemas() {
    this.perfilService.listarSistemas().subscribe(
      success => {
        this.sistemas = success.data;
      }
    )
  }

  /* listarAtalhos(){
     this.atalhoService.loadAtalhos(1).subscribe(
       success => {
      success.data.records.forEach(atalho=> {
 console.log(atalho)
        this.items.push({label: atalho.nome, url: atalho.url, icon: atalho.icone},)

      })}
      )
   }*/

  confirmarSistema() {
    this.modalSistema = false;
    this.accountService.setSystem(this.sistemaSelecionado);
  }

  logout() {
    this.modalService.show('modal-logout');
    this.accountService.logout().subscribe();
  }

  showSearchBar() {
    return this.router.url == '/colaborador' || this.router.url == '/vendas';
  }

  showSidebar() {
    this.minhaNotificacaoService.showNotifications = true;
  }

  isOnHomePage() {
    return this.router.url == '/';
  }

  buscar() {
    const input = document.getElementById('search-input') as HTMLInputElement;
    const value = input.value.trim().toLowerCase();

    const titles = document.getElementById('atalhos-container').getElementsByClassName('title');
    Array.prototype.forEach.call(titles, function (title) {
      const titleElem = title as HTMLElement;
      const cardContainer = titleElem.parentElement.parentElement;
      if (titleElem.innerText.toLowerCase().includes(value)) {
        cardContainer.classList.remove('d-none');
      } else {
        cardContainer.classList.add('d-none');
      }
    });
  }
}
