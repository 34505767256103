import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TipoClienteMapping} from "../../_models/enums/tipoCliente";
import {Cliente} from "../../_models/cliente";
import {SituacaoClienteMapping} from "../../_models/enums/situacaoCliente";
import {ClienteService} from "../../_services/ClienteService";
import {AccountService, AlertService, PermissionService} from "../../_services";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-incluir-cliente',
  templateUrl: './incluir-cliente.component.html',
  styleUrls: ['./incluir-cliente.component.css']
})
export class IncluirClienteComponent implements OnInit {

  constructor(
    private clienteService:ClienteService,
    private alertService:AlertService,
    private router:Router, private route:ActivatedRoute,
    private fb:UntypedFormBuilder,
    public permissionService: PermissionService,
    public accountService:AccountService) { }

  formCliente:UntypedFormGroup;
  cliente:Cliente
  uuid;
  tipoCliente;
  tiposCliente=TipoClienteMapping
  situacao;
  situacoes=SituacaoClienteMapping
  uf;
  ngOnInit(): void {
    this.getUf(this.accountService.userValue.menu,'CAD01');
    this.createForm(new Cliente());
    this.route.queryParams
      .subscribe(params => {
        this.uuid=params['uuid'];
      });
    this.buscarCliente();
  }
  salvarCliente(){
    var cliente = this.formCliente.value;
    if(this.uuid==undefined) {
      this.clienteService.CadastrarCliente(cliente).subscribe(res=>{
        if(res.success){
          this.uuid=res.data.uuid;
          this.alertService.success(res.messages.join('<br/>'));
          this.router.navigate(['/clientes/incluir'],{queryParams:{uuid:this.uuid}})
        }else{
          this.alertService.error(res.messages.join('<br/>'));
        }
      },error => {
        this.alertService.error(error)
      })
    }else{
      this.clienteService.AlterarCliente(cliente).subscribe(res=>{
        if(res.success){
          this.alertService.success(res.messages.join('<br/>'));
          this.uuid=res.data.uuid;
        }else{
          this.alertService.error(res.messages.join('<br/>'));
        }
      },error => {
        this.alertService.error(error)
      });
    }
  }
  cancelar(){
    location.reload()
  }
  buscarCliente(){
    if(this.uuid!=undefined){
      this.clienteService.BuscarCliente(this.uuid).subscribe(
        res => {
          this.createForm(res)
        }, error => {
          this.alertService.error(error);
          this.router.navigate(['/clientes/incluir']);
        }
      )
    }
  }
  createForm(cliente:Cliente){
    this.formCliente=this.fb.group({
      id:[cliente.id],
      uuid:[cliente.uuid],
      codigo:[cliente.codigo],//new FormControl({value: cliente.codigo, disabled: true}),
      nome:[cliente.nome],
      cpf:[cliente.cpf],
      cnpj:[cliente.cnpj],
      tipoCliente:[cliente.tipoCliente],
      situacao:[cliente.situacao],
      nomeResponsavel:[cliente.nomeResponsavel],
      emailResponsavel:[cliente.emailResponsavel],
      telefoneResponsavel:[cliente.telefoneResponsavel]
    });
    this.formCliente.get('situacao')?.disable();
  }

  getUf(base,uf):any{
    if(base==undefined) return {codigo:'',nome:''};
    let data = base.filhos.find(x=>x.codigo==uf);
    if(data !=undefined){
      this.uf= data;
    }
    base.filhos.forEach(x=>{
      return this.getUf(x, uf);
    });
  }

}
