import { Component, Input, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {AccountService, AlertService, ModalService, PermissionService} from "../../_services";
import { ColumnType } from '../../_models/enums/column-type';
import { TableService } from '../../_services/table.service';
import { FiltroAuditoria } from '../../_models/filtros/filtro-auditoria';
import { DatePipe } from '@angular/common';
import { Auditoria } from '../../_models/auditoria';
import { ModalVisualizacao } from '../../_models/components/modal-visualizacao';


@Component({
  selector: 'app-seguranca',
  templateUrl: './seguranca.component.html',
  styleUrls: [ './seguranca.component.css' ],
  providers: [
    TableService
  ]
})
export class SegurancaComponent implements OnInit {

  formSeguranca: UntypedFormGroup;
  invalidForm: boolean = true;
  loading: boolean = false;
  loadingExportar: boolean = false;
  conteudo: string[];
  modelVisualizacao: ModalVisualizacao;
  uf;
  
  constructor(private formBuilder: UntypedFormBuilder,
              private datePipe: DatePipe,
              private modalService: ModalService,
              private accountService: AccountService,
              public permissionService: PermissionService,
              public table: TableService<Auditoria>) { }

  ngOnInit(): void {
    this.createForm(new FiltroAuditoria());
    this.getUf(this.accountService.userValue.menu,'SEG08')
    this.table.cols = [
      { field: 'atualizadoEm', header: 'Data de Acesso', width: '15%', type: ColumnType.Date, format: 'dd/MM/yyyy' },
      { field: 'tempoAcesso', header: 'Tempo de Acesso', width: '25%' },
      { field: 'servidor', header: 'Tipo de Acesso', width: '25%', class: 'text-truncate' },
      { field: 'areaAcessada', header: 'Área Acessada', width: '20%' }
    ];
    this.table.setColActionsWidth('15%').withUrlAction('auditoria').withFilter(this.formSeguranca.value);
    this.onChanges();
  }

  createForm(auditoria: FiltroAuditoria){
    this.formSeguranca = this.formBuilder.group({
      dataInicial: [auditoria.dataInicial],
      dataFinal: [auditoria.dataFinal],
      nome: [auditoria.nome],
      email: [auditoria.email],
      login: [auditoria.login]
    })
  }

  onChanges() {
    this.formSeguranca.valueChanges.subscribe(val => {
      this.invalidForm = Object.values(val).every(item => !item);
    })
  }

  visualizar(model: Auditoria) {
      this.conteudo = [
        model.nomeUsuario,
        model.login,
        model.areaAcessada,
        this.datePipe.transform(model.atualizadoEm, 'dd/MM/yyyy'),
        model.tempoAcesso,
        model.servidor,
        model.email
      ]
      this.modelVisualizacao = new ModalVisualizacao({titulo: "Detalhes do Acesso", content: this.conteudo});
      this.modalService.abrirModalVisualizar();
  }

  buscar() {
    this.table.withFilter(this.formSeguranca.value).reloadData();
  }

  exportar() {
    if (!this.loadingExportar) {
      this.loadingExportar = true;
      this.table.exportar('auditoria/exportar').add(() => {
        this.loadingExportar = false;
      });
    }
  }

  refresh() {
    this.createForm(new FiltroAuditoria());
    this.table.request.cleanFilter();
    this.table.withFilter(this.formSeguranca.value).reloadData();
    this.table.reloadData();
  } 

  getUf(base,uf):any{
    if(base==undefined) return {codigo:'',nome:''};
    let data = base.filhos.find(x=>x.codigo==uf);
    if(data !=undefined){
      this.uf= data;
    }
    base.filhos.forEach(x=>{
      return this.getUf(x, uf);
    });
  }  
}
