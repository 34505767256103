import {Sistema} from "./sistema";

export class ParametroRequest{
    sistema;
    chave;
    descricao;
    valor;
    nome;

    constructor() {
      this.sistema={codigoSistema:'HUBHAWK',nome:'HUBHAWK'};
    }
}
