import * as fileSaver from 'file-saver';
import { FileDto } from '../_models/file-dto';

export function downloadFile(file: any, type: string, name: string) {
  let blob: any = new Blob(file, { type });
  fileSaver.saveAs(blob, name);
}

export function downloadFileDto(file: FileDto) {
  const byteArr = b64toByteArr(file.bytes);
  downloadFile(byteArr, file.contentType, `${file.fileName}.${file.extension}`);
}

function b64toByteArr(b64Data) {
  var sliceSize = 512;
  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);

    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return byteArrays;
}
