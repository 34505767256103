export class DataSourceRequest {
  order: Array<{ column: string, value: string}> = [];
  filter: Array<{ column: string, value: string}> = [];
  search: string;
  draw: string;
  start: number = 0;
  pageSize: number;

  addFilter(column, value) {
    this.filter.push({ column, value });
    return this;
  }

  setOrder(column, order) {
    this.order = [{ column, value: order }];
    return this;
  }

  cleanFilter() {
    this.filter.length = 0;
    return this;
  }
}
