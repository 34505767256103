import {SituacaoCliente} from "../enums/situacaoCliente";
import {DataSourceRequest} from "../data-source-request";

export class FiltroClienteDto{
  request:DataSourceRequest;
  NomeCliente:string;
  CpfCnpj :string;
  Responsavel :string;
  Telefone :string;
  Situacao :SituacaoCliente[];
}
