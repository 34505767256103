<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <nav arial-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/">Portal</a></li>
          <li class="breadcrumb-item"><a routerLink="/">Administração</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{uf.nome}}</li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="row justify-content-between">
    <div class="col-1"><h2>{{uf.nome}}</h2></div>
    <div class="col-1" style="display: flex;justify-content: flex-end;">
      <button pButton pRipple label="Incluir" (click)="showDialog()" icon="pi pi-plus" class="p-button p-button-success"
              *ngIf="permissionService.hasPermission(uf.codigo,'Incluir')"></button>
    </div>
  </div>
  <div class="row" *ngIf="permissionService.hasPermission(uf.codigo,'Visualizar')">
    <p-table dataKey="id" editMode="row"
             responsiveLayout="scroll"
             [(first)]="this.table.request.start"
             class="col-12" styleClass="p-datatable-gridlines p-datatable-striped"
             [lazy]="true"
             [columns]="table.cols"
             [value]="table.records"
             (onLazyLoad)="this.table.init2($event)"
             sortField="nome" [sortOrder]="-1"
             [loading]="table.loading"
             [showCurrentPageReport]="true"
             [paginator]="true"
             [rows]=table.rowPerPage[0]
             [rowsPerPageOptions]=table.rowPerPage
             [totalRecords]="table.total">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th [ngStyle]="{'width': table.colActionsWidth}">Ações</th>
          <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" [ngStyle]="{'width': col.width}">
            <p-sortIcon field="{{col.field}}"></p-sortIcon>
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td class="text-center col-actions">
            <p-splitButton icon="pi pi-list" [model]="acoesTabela" (onDropdownClick)="opcaoSelecionada(rowData)"></p-splitButton>
          </td>
          <td *ngFor="let col of columns" class="{{col.class}}" class="text-center">
            <p>
              <a *ngIf="col.field=='url'"
                 ng-href="google.com" target="_blank"
                 [innerHtml]="rowData[col.field] | columnRender : col.type:col.format"></a>
            </p>
            <p *ngIf="col.field!='url'" [innerHtml]="rowData[col.field] | columnRender : col.type:col.format"> </p>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td class="rowEmpty" [colSpan]="this.table.cols.length+1">
            <p *ngIf="table.records==undefined">Clique em filtrar para realizar uma busca</p>
            <p *ngIf="table.records!=undefined && table.records.length==0">Nenhum registro foi encontrado</p>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

<!--  <section [hidden]="atalhoCriado">-->
<!--    <div class="header">-->
<!--      <p class="title">Novo Atalho</p>-->
<!--      <span class="subtitle">Preencha os campos para criar um novo atalho</span>-->
<!--    </div>-->
<!--    <form class="content" [formGroup]="formAtalho">-->
<!--      <div class="grid-left">-->
<!--        <div class="form-group">-->
<!--          <input type="text" class="form-control" placeholder="Nome do atalho:" formControlName="nome">-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--          <textarea rows="1" class="form-control" placeholder="Descrição do atalho:"-->
<!--                    formControlName="descricao"></textarea>-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--          <input type="text" class="form-control" placeholder="URL:" formControlName="url">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="grid-right">-->



<!--      </div>-->
<!--      <div class="grid-button-left">-->
<!--        <button type="button" class="button button_red" (click)="resetarForm()" *ngIf="!modoInclusao">-->
<!--          CANCELAR-->
<!--        </button>-->
<!--      </div>-->
<!--      <div class="grid-button-right">-->
<!--        <button type="button" class="button button_red" [disabled]="formAtalho.invalid" (click)="salvar()">-->
<!--          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>-->
<!--          <span *ngIf="modoInclusao">CRIAR ATALHO</span>-->
<!--          <span *ngIf="!modoInclusao">ATUALIZAR ATALHO</span>-->
<!--        </button>-->
<!--      </div>-->
<!--    </form>-->
<!--  </section>-->
<!--  <section [hidden]="!atalhoCriado">-->
<!--    <div class="d-inline-block" style="max-width: 320px;">-->
<!--      <div class="header">-->
<!--        <p class="title">Atalho criado com sucesso!</p>-->
<!--        <span class="subtitle">Gostaria de criar um atalho novo?</span>-->
<!--      </div>-->
<!--      <div class="mt-5">-->
<!--        <button type="button" class="button button_red mb-3" (click)="atalhoCriado = false">CRIAR ATALHO</button>-->
<!--        <button type="button" class="button button_yellow" routerLink="/administracao">VOLTAR AO DASHBOARD</button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->

<!--  <div *ngIf="permissionService.hasPermission(uf.codigo,'Listar')" class="mt-5">-->
<!--    <h4 class="font-weight-semi-bold">Atalhos</h4>-->
<!--    <p-table [columns]="table.cols" [value]="table.records" [lazy]="true" (onLazyLoad)="this.table.init($event)"-->
<!--             responsiveLayout="scroll" [(first)]="this.table.request.start"-->
<!--             [paginator]="false" [rows]="10" [totalRecords]="table.total" [loading]="table.loading" sortField="nome"-->
<!--             [sortOrder]="1">-->
<!--      <ng-template pTemplate="header" let-columns>-->
<!--        <tr>-->
<!--          <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" [ngStyle]="{'width': col.width}">-->
<!--            <p-sortIcon field="{{col.field}}"></p-sortIcon>-->
<!--            {{col.header}}-->
<!--          </th>-->
<!--          <th style="text-align:center" [ngStyle]="{'width': table.colActionsWidth}">Ações</th>-->
<!--        </tr>-->
<!--      </ng-template>-->
<!--      <ng-template pTemplate="body" let-rowData let-columns="columns">-->
<!--        <tr>-->
<!--          <td *ngFor="let col of columns" class="{{col.class}}"-->
<!--              [innerHtml]="rowData[col.field] | columnRender : col.type:col.format"></td>-->
<!--          <td class="text-center col-actions">-->
<!--            <button pButton pRipple icon="pi pi-pencil" *ngIf="permissionService.hasPermission(uf.codigo, 'Atualizar')"-->
<!--                    title="Editar" (click)="editar(rowData)"-->
<!--                    class="p-element p-ripple p-button-rounded p-button-primary p-button-text p-button p-component p-button-icon-only m-r-2">-->
<!--            </button>-->
<!--            <button pButton pRipple icon="pi pi-trash" *ngIf="permissionService.hasPermission(uf.codigo, 'Remover')"-->
<!--                    title="Excluir" (click)="preExcluir(rowData)"-->
<!--                    class="p-element p-ripple p-button-rounded p-button-primary p-button-text p-button p-component p-button-icon-only">-->
<!--            </button>-->
<!--          </td>-->
<!--        </tr>-->
<!--      </ng-template>-->
<!--    </p-table>-->
<!--    <div class="row">-->
<!--      <div class="col-1" style="padding-top: 10px;">-->
<!--        <p-button icon="pi pi-refresh" (onClick)="refresh()"></p-button>-->
<!--      </div>-->
<!--      <div class="col-10">-->
<!--        <p-paginator (onPageChange)="paginate($event)" [rows]="10" [totalRecords]="table.total"-->
<!--                     [rowsPerPageOptions]="[10,25,50,100]"></p-paginator>-->
<!--      </div>-->
<!--    </div>-->


<!--  </div>-->
</div>

<p-confirmDialog [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
  <ng-template pTemplate="header">
    <h3>Confirme esta ação</h3>
  </ng-template>
</p-confirmDialog>

<p-dialog header="Adcionar Atalho" [(visible)]="modalInclusao">
  <form [formGroup]="formAtalho">
    <div class="row">
      <div class="col-12" style="display: flex;justify-content: flex-end;">
        <label class="switch">
          <input type="checkbox" formControlName="notificacao">
          <span class="slider round"></span>
        </label>
        <span class="span-notificacao" style="margin-left:1%">Criar Notificação</span>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-6">
            <span class="p-float-label">
                <input id="float-input" pInputText type="text" class="form-control" formControlName="nome">
                <label for="float-input">Nome do Atalho</label>
            </span>
      </div>
      <div class="col-5 offset-1">
        <p-dropdown [options]="categories" optionLabel="name" optionValue="code" formControlName="tipo"
                    placeholder="Cockpit (Selecione)"></p-dropdown>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-6">
            <span class="p-float-label">
                <input id="float-input" pInputText type="text" class="form-control" formControlName="url">
                <label for="float-input">Url</label>
            </span>
      </div>
      <div class="col-5 offset-1">
        <app-dropdown-icons formControlName="icone"></app-dropdown-icons>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-12">
            <span class="p-float-label">
                <textarea id="float-input" pInputText type="text" class="form-control" formControlName="descricao"></textarea>
                <label for="float-input">Descição</label>
            </span>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <div style="display: flex;justify-content: flex-end;">
      <button pButton pRipple icon="pi pi-save" type="button"
              (click)="salvarAtalho()" label="Salvar" class="p-button-info"
              *ngIf="permissionService.hasPermission(uf.codigo,'Incluir')">
      </button>
      <button pButton pRipple icon="pi pi-times" type="button"
              (click)="cancelar()" label="Cancelar" class="p-button-danger">
      </button>
    </div>
  </ng-template>
</p-dialog>

<app-modal-exclusao [model]="modelExclusao" (deleteEvent)="excluir($event)"></app-modal-exclusao>
