<div class="container-fluid">
  <div class="row justify-content-between no-gutters">
    <div class="col-4"><h2>{{uf.nome}}</h2></div>
    <div class="col-2" style="display: flex;justify-content: flex-end;">
      <button pButton pRipple label="Incluir" (click)="showDialog()" icon="pi pi-plus" class="p-button p-button-success"
              *ngIf="permissionService.hasPermission(uf.codigo,'Incluir')"></button>
    </div>
  </div>
  <br>
  <form [formGroup]="formParametro">
    <div class="row">
        <div class="col-2">
          <span class="p-float-label">
            <p-dropdown class="dropDownSistemas" (onChange)="filtrar()" [options]="sistemas"
                        formControlName="codigoSistema" optionLabel="nome" optionValue="codigo"
                        [autoDisplayFirst]="false"></p-dropdown>
            <label for="float-input">Sistema</label>
          </span>
        </div>
        <div class="col-3">
            <span class="p-float-label">
                <input id="float-input" pInputText type="text" class="form-control" formControlName="nome">
                <label for="float-input">Chave, valor ou descrição</label>
            </span>
        </div>
        <div class="col-2  offset-5" style="display: flex;justify-content: flex-end;">
          <button pButton pRipple icon="pi pi-search" type="button"
                  (click)="filtrar()" label="Filtrar" class="p-button-primary"
                  *ngIf="permissionService.hasPermission(uf.codigo,'Visualizar')">
          </button>
          <button pButton pRipple icon="pi pi-filter" type="button"
                  (click)="limpar()" label="Limpar" class="p-button-secondary">
          </button>
        </div>
    </div>
  </form>
  <div class="row" *ngIf="permissionService.hasPermission(uf.codigo,'Visualizar')">
    <p-table dataKey="id" editMode="row"
             responsiveLayout="scroll"
             [(first)]="this.table.request.start"
             class="col-12" styleClass="p-datatable-gridlines p-datatable-striped"
             [lazy]="true"
             [columns]="table.cols"
             [value]="table.records"
             (onLazyLoad)="this.table.init2($event)"
             sortField="chave" [sortOrder]="-1"
             [loading]="table.loading"
             [showCurrentPageReport]="true"
             [paginator]="true"
             [rows]=table.rowPerPage[0]
             [rowsPerPageOptions]=table.rowPerPage
             [totalRecords]="table.total">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th [ngStyle]="{'width': table.colActionsWidth}">Ações</th>
          <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" [ngStyle]="{'width': col.width}">
            <p-sortIcon field="{{col.field}}"></p-sortIcon>
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="product">
          <td class="text-center col-actions">
            <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                    (click)="table.onRowEditInit(product)" class="p-button-rounded p-button-text"></button>
            <button *ngIf="!editing" pButton pRipple type="button" icon="pi pi-trash"
                    (click)="confirmarExclusao(product)"
                    class="p-button-rounded p-button-text p-button-danger"></button>
            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                    (click)="onRowEditSave(product)"
                    class="p-button-rounded p-button-text p-button-success mr-2"></button>
            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                    (click)="table.onRowEditCancel(product, ri)"
                    class="p-button-rounded p-button-text p-button-danger"></button>
          </td>
          <td class="text-center" style="padding: 5px;">
            <p-cellEditor class="dataTableCenterAlign ">
              <ng-template pTemplate="input">
                <p-dropdown appendTo="body" [(ngModel)]="sistemaEdit" [options]="sistemas" placeholder="Selecione o sistema"
                            optionLabel="nome"></p-dropdown>
              </ng-template>
              <ng-template pTemplate="output" class="dataTableCenterAlign">
                <div class="dataTableCenterAlign">{{product.codigoSistema}}</div>
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="text-center" style="padding: 5px;">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" class="dataTableCenterAlign form-control" [(ngModel)]="product.chave">
              </ng-template>
              <ng-template pTemplate="output" class="dataTableCenterAlign">
                <div class="dataTableCenterAlign">{{product.chave}}</div>
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="text-center" style="padding: 5px;">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" class="dataTableCenterAlign form-control" [(ngModel)]="product.valor">
              </ng-template>
              <ng-template pTemplate="output" class="dataTableCenterAlign">
                <div class="dataTableCenterAlign">{{product.valor}}</div>
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="text-center" style="padding: 5px;">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" class="dataTableCenterAlign form-control" [(ngModel)]="product.descricao">
              </ng-template>
              <ng-template pTemplate="output">
                {{product.descricao}}
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td class="rowEmpty" [colSpan]="this.table.cols.length+1">
            <p *ngIf="table.records==undefined">Clique em filtrar para realizar uma busca</p>
            <p *ngIf="table.records!=undefined && table.records.length==0">Nenhum registro foi encontrado</p>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-confirmDialog [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
  <ng-template pTemplate="header">
    <h3>Confirme esta ação</h3>
  </ng-template>
</p-confirmDialog>

<p-dialog header="Adicionar parâmetro" [style]="{width: '50vw'}" [(visible)]="modalInclusao">
  <form [formGroup]="formInclusao">
    <div class="row">
      <div class="col-5">
        <p-dropdown class="dropDownSistemas" appendTo="body" formControlName="sistema" [options]="sistemas"
                    placeholder="Selecione o sistema" optionLabel="nome" [showClear]="true"></p-dropdown>
      </div>
      <div class="col-5 offset-2">
        <span class="p-float-label">
          <input id="float-input" type="text" pInputText class="form-control" formControlName="chave">
          <label for="float-input">Chave</label>
        </span>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-5">
        <span class="p-float-label">
          <input id="float-input" type="text" pInputText class="form-control" formControlName="descricao">
          <label for="float-input">Descrição</label>
        </span>
      </div>
      <div class="col-5 offset-2">
        <span class="p-float-label">
          <input id="float-input" type="text" pInputText class="form-control" formControlName="valor">
          <label for="float-input">Valor</label>
        </span>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <div class="flexEnd">
      <button pButton pRipple icon="pi pi-save" type="button"
              (click)="confirmarInclusao(formInclusao.value)" label="Salvar" class="p-button-success"
              *ngIf="permissionService.hasPermission(uf.codigo,'Incluir')">
      </button>
      <button pButton pRipple icon="pi pi-times" type="button"
              (click)="cancelarInclusao()" label="Cancelar" class="p-button-secondary">
      </button>
    </div>
  </ng-template>
</p-dialog>
