import { Component, OnInit } from '@angular/core';
import { Atalho } from '../../_models/atalho';
import { AlertService } from '../../_services';
import { AtalhoService } from '../../_services/atalho.service';

@Component({
  selector: 'app-cockpit-vendas',
  templateUrl: './cockpit-vendas.component.html',
  styleUrls: ['./cockpit-vendas.component.css']
})
export class CockpitVendasComponent implements OnInit {
  atalhos: Atalho[] = null;

  constructor(private atalhoService: AtalhoService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.loadAtalhos();
  }

  loadAtalhos() {
    this.atalhoService.loadAtalhos(2).subscribe(
      data => {
        this.atalhos = data.data.records;
      },
      error => {
        this.alertService.error(error);
      });
  }
}
