import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Atalho } from '../../_models/atalho';

@Component({
  selector: 'app-cards-atalhos',
  templateUrl: './cards-atalhos.component.html',
  styleUrls: ['./cards-atalhos.component.css']
})
export class CardsAtalhosComponent implements OnInit {
  @Input() atalhos: Atalho[] = null;

  constructor(private dom: DomSanitizer) { }

  ngOnInit(): void {
  }

  atalhoHover(descricao: string): void {
    document.getElementById('atalho-description').innerHTML = descricao;
    document.querySelectorAll(".on-card-hover").forEach(elem => {
      elem.classList.add("card-hovered");
    });
  }

  atalhoLeave(): void {
    document.getElementById('atalho-description').innerHTML = "";
    document.querySelectorAll(".on-card-hover").forEach(elem => {
      elem.classList.remove("card-hovered");
    });
  }
}
