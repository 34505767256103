import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'greetings'
})
export class GreetingsPipe implements PipeTransform {

  transform(hour: number) {
    let greetings = 'Bom dia,'

    if(hour >= 12 && hour <= 17){
      greetings = 'Boa tarde,'
    }
    if(hour > 17 && hour < 24) {
      greetings = 'Boa noite,'
    }

    return greetings;
  }

}
