import { Component, OnInit } from '@angular/core';
import { PermissionService } from '../../_services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  cards = {
    atalho: {descricao: 'Configure e gerencie os atalhos presentes no PTAI'},
    notificacao: {descricao: 'Configure as mensagens de notificação do portal'},
    usuarios: {descricao: 'Gerencie os usuários dos sistemas'},
    perfis: {descricao: 'Gerencie os perfis do sistema'},
    perfilsistemagrupoad: {descricao: 'Associação de perfis com Grupos do Active Directory'},
    seguranca: {descricao: 'Acesse os relatórios de auditoria'},
    permissoes: {descricao: 'Gerencie as permissões de perfil'},
    parametros: {descricao: 'Gerencie Parâmetros'},
    parametrosSistema: {descricao: 'Gerencie Parâmetros de Sistema'},
  }

  constructor(
    public permissionService: PermissionService
  ) { }

  ngOnInit(): void {
  }

  cardHover(card): void {
    document.getElementById('card-description').innerHTML = card.descricao;
    document.querySelectorAll(".on-card-hover").forEach(elem => {
      elem.classList.add("card-hovered");
    });
  }

  cardLeave(): void {
    document.getElementById('card-description').innerHTML = "";
    document.querySelectorAll(".on-card-hover").forEach(elem => {
      elem.classList.remove("card-hovered");
    });
  }
}
