export enum TipoCliente {
  Fisica=1,
  Juridica=2
}
export const TipoClienteMapping = [
  { value: TipoCliente.Fisica, nome: "Física"},
  { value: TipoCliente.Juridica, nome: "Jurídica"},
]

export const TipoClienteList = Object.keys(TipoCliente)
  .filter((v) => isNaN(Number(v)))
  .map((name) => {
    return {
      name,
      code: TipoCliente[name as keyof typeof TipoCliente]
    };
  });
