<div id="main-container" class="main-container">
  <app-header *ngIf="user"></app-header>
  <!-- main app container -->
  <div class="app-container {{isAdm()? 'bg-admin' : 'bg-teia'}}">
    <alert></alert>
    <router-outlet></router-outlet>
  </div>

  <footer class="footer {{isAdm()? 'blue' : 'red'}}" *ngIf="user">
    <img src="../assets/svg/logo-tenda-with-text-white.svg" height="20" />
  </footer>
</div>
<app-sidebar *ngIf="user && permissionService.hasPermission('NTF02', 'Listar')"></app-sidebar>
