import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {finalize, map} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {User, BaseResponse, LoginResponse} from '../_models';

@Injectable({providedIn: 'root'})
export class AccountService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  private userLogado: User;

  private system$ = new BehaviorSubject<any>({});
  selectecSystem$ = this.system$.asObservable();

  setSystem(sistema: any) {
    this.system$.next(sistema);
  }

  getSystem(){
    return this.system$;
  }

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.verificarAcessoValido();
  }
    cleanAllLocalStorage() {
      localStorage.clear()
    }

  public get userValue(): User {
    return this.userSubject.value;
  }

  private verificarAcessoValido() {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
  }

    

  login(login, senha, sistema) {
    this.cleanAllLocalStorage();
    return this.http.post<BaseResponse<LoginResponse>>(`${environment.apiUrl}/security/login`, {login, senha, sistema})
      .pipe(map(baseResponse => {
        let user = new User();
        user.firstName = baseResponse.data.nomeUsuario;
        user.username = baseResponse.data.login;
        user.token = baseResponse.data.token;
        user.id = baseResponse.data.idUsuario;
        user.dateAccess = new Date();
        user.permissoes = baseResponse.data.permissoes;
        user.menu = baseResponse.data.menu;
        user.codigoSistema=baseResponse.data.codigoSistema;
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;
      }));
  }

  logout() {
    return this.http.post<BaseResponse<string>>(`${environment.apiUrl}/security/logout`, {})
      .pipe(finalize(() => {
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/account/login']);
      }));
  }

  findLastAccessByUser(loginUsuario){
    return this.http.get<BaseResponse<any>>(`${environment.apiUrl}/usuario/${loginUsuario}/acesso`);
  }

  getUf(base,uf):any{
    if(base==undefined) return {codigo:'',nome:''};
    let data = base.filhos.find(x=>x.codigo==uf);
    if(data !=undefined){
      console.log(data)
      return data;
    }
    base.filhos.forEach(x=>{
      if(x.codigo == uf){
        return this.getUf(x,uf);
      }else {
        return this.getUf(x, uf);
      }
    });
  }
}
