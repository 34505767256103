import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Notificacao } from 'src/app/_models/notificacao';
import { MinhaNotificacaoService } from 'src/app/_services/minhanotificacao.service';
import { AlertService, PermissionService } from '../../_services';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  uf: string = 'NTF02';
  notificacoes: Notificacao[] = [];

  constructor(public permissionService: PermissionService, public minhaNotificacaoService: MinhaNotificacaoService,
    public alertService: AlertService) { }

  ngOnInit() {
    this.loadMinhasNotificacoes();
  }

  sidebarClosed() {
    if (this.minhaNotificacaoService.showNotifications) {
      // TODO: melhorar isso
      const container = document.getElementById('notifications-container');
      if (container) {
        const notifications = container.getElementsByClassName('notification-box');
        Array.prototype.forEach.call(notifications, function (notification) {
          const notifElem = notification as HTMLElement;
          notifElem.classList.add('read');
        });
        document.getElementById('icon-bell-none').classList.remove('d-none');
        document.getElementById('icon-bell-red').classList.add('d-none');
      }
    }
  }

  loadMinhasNotificacoes() {
    this.minhaNotificacaoService.loadMinhasNotificacoes().subscribe(
      data =>
      {
        this.notificacoes = data.data;
        if (this.notificacoes.length > 0) {
          this.showNotificationsOn();
        }
      },
      error =>
      {
        this.alertService.error(error);
      });
  }


  closeNotification(event, idNotificacao) {
    const target = event.target as HTMLElement;
    const notificationBox = target.parentElement.parentElement;
    notificationBox.classList.add('fadeOutRight');
     
    this.minhaNotificacaoService.marcarLida(idNotificacao).subscribe(
      data =>
      {       
      },
      error =>
      {
        this.alertService.error(error);
      });

    setTimeout(function () {
      notificationBox.remove();
    }, 500);
  }

  showNotificationsOn() {
    document.getElementById('icon-bell-none')?.classList.add('d-none');
    document.getElementById('icon-bell-red')?.classList.remove('d-none');
  }

  showNotificationsOff() {
    document.getElementById('icon-bell-none')?.classList.remove('d-none');
    document.getElementById('icon-bell-red')?.classList.add('d-none');
  }
}
