<div class="container-fluid">
    <div class="row justify-content-between no-gutters">
        <h2 style="margin-top: 1.5%;">Editar Usuário</h2>
    </div>
    <div class="row no-gutters">
        <form [formGroup]="formUsuario" style="width: 100%;">
            <div class="form-group content">
                <div class="row flexEnd">
                    <div class="col-4">
                        <span class="p-float-label">
                            <p-dropdown [options]="situacoes" formControlName="situacao" optionLabel="nome" optionValue="value"></p-dropdown>
                            <label for="float-input">Situação</label>
                        </span> 
                    </div>
                </div>
                <div class="row" style="margin-top: 2%;">
                    <div class="col-6">
                        <span class="p-float-label">
                            <input id="float-input" pInputText type="text" formControlName="nome" class="form-control" minlength="5" maxlength="128"> 
                            <label for="float-input">Nome</label>
                        </span>  
                    </div>
                    <div class="col-6">
                        <span class="p-float-label">
                            <input id="float-input" pInputText type="text" formControlName="email" class="form-control" minlength="10" maxlength="128"> 
                            <label for="float-input">E-mail</label>
                        </span>  
                    </div>
                </div>
                <div class="row" style="margin-top: 2%;">
                    <div class="col-4">
                        <span class="p-float-label">
                            <p-inputMask *ngIf="formUsuario.controls['telefone'].value.length == 14" id="float-input" type="text" formControlName="telefone" [autoClear]="false" mask="(99) 9999-9999"></p-inputMask>
                            <p-inputMask *ngIf="formUsuario.controls['telefone'].value.length != 14" id="float-input" type="text" formControlName="telefone" [autoClear]="false" mask="(99) 99999-9999"></p-inputMask>
                            <label for="float-input">Telefone</label>
                        </span>  
                    </div>
                    <div class="col-4">
                        <span class="p-float-label">
                            <p-dropdown id="float-input" [autoDisplayFirst]="false" [options]="clientes" formControlName="cliente" optionLabel="nome"></p-dropdown>
                            <label for="float-input">Cliente</label>
                        </span>  
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="flexEnd">
                        <button class="p-button-success" icon="pi pi-save" label="Salvar" pButton
                            (click)="alterarUsuario()" type="button"></button>
                        <button class="p-button-secondary" icon="pi pi-times" label="Cancelar" pButton
                                (click)="voltar()" type="button" style="margin-left: 2%;"></button>
                    </div>
                </div>
            </div>
        </form>
    </div> 
</div>
