<div class="container-fluid">
    <div class="header">
      <h2>Associação de Perfis com Grupos do AD</h2>
      <span class="subtitle">Selecione um Sistema e associe seus perfis com grupos do Active Directory</span><br>
    </div>
    <div class="content">
      <div class="row p-fluid">
        <div class="col-5 form-group">
          <p-dropdown *ngIf="permissionService.hasPermission('SEG02','Listar')" [options]="sistemas" [(ngModel)]="selectedSistema" optionLabel="nome" (onChange)="changeSistema($event)" placeholder="Selecione o sistema desejado..."></p-dropdown>
        </div>
        <div class="col-6 offset-1 flexEnd">
          <button pButton pRipple icon="pi pi-plus" type="button" #incluirPerfil
                 (click)="addPerfilSistemaGrupoAd()" label="Incluir" class="p-button-success">
          </button>          
          <button class="p-button-info" icon="pi pi-file-excel" label="Exportar Todos" pButton
                        (click)="exportarTodos()" type="button">
            <span *ngIf="loadingExportarTodos" class="spinner-border spinner-border-sm mr-1"></span>       
          </button>
          <button class="p-button-info" icon="pi pi-file-excel" label="Exportar Página" pButton
                        (click)="exportarPagina()" type="button">
            <span *ngIf="loadingExportarPagina" class="spinner-border spinner-border-sm mr-1"></span>       
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="true">
      <p-table dataKey="idPerfil" editMode="row"
               responsiveLayout="scroll" 
               [(first)]="this.table.request.start" 
               class="col-12" styleClass="p-datatable-gridlines p-datatable-striped"
               [lazy]="true"
               [columns]="table.cols" 
               [value]="table.records" 
               (onLazyLoad)="this.table.init($event)" 
               [loading]="table.loading"
               [showCurrentPageReport]="true"
               [paginator]="true"
               [rows]=table.rowPerPage[0]
               [rowsPerPageOptions]=table.rowPerPage
               [totalRecords]="table.total"
               sortField="nomePerfil" 
               [sortOrder]="1">
      <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="text-align:center" [ngStyle]="{'width': table.colActionsWidth}">Ações</th>
            <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" [ngStyle]="{'width': col.width}">
              <p-sortIcon field="{{col.field}}"></p-sortIcon>
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-rowIndex="rowIndex">
          <tr [pEditableRow]="rowData">
            <td class="text-center col-actions">
              <!-- <button *ngIf="!editing && !isAdding && permissionService.hasPermission('SEG02','Atualizar')" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(rowData)" class="p-button-rounded p-button-text"></button>
              <button *ngIf="!editing && !isAdding && permissionService.hasPermission('SEG02','Remover')" pButton pRipple type="button" icon="pi pi-trash" (click)="preExcluir(rowData)" class="p-button-rounded p-button-text"></button>
              <button *ngIf="!editing && isAdding && rowIndex != 0 && permissionService.hasPermission('SEG02','Remover')" pButton pRipple type="button" icon="pi pi-trash" (click)="preExcluir(rowData)" class="p-button-rounded p-button-text"></button>

              <button *ngIf="editing && permissionService.hasPermission('SEG02','Atualizar')" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(rowData, rowIndex)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
              <button *ngIf="editing && permissionService.hasPermission('SEG02','Atualizar')" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(rowData, rowIndex)" class="p-button-rounded p-button-text p-button-danger"></button> -->


              <button *ngIf="!editing && !isAdding" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(rowData)" class="p-button-rounded p-button-text"></button>
              <button *ngIf="!editing && !isAdding" pButton pRipple type="button" icon="pi pi-trash" (click)="preExcluir(rowData)" class="p-button-rounded p-button-text"></button>
              <button *ngIf="!editing && isAdding && rowIndex != 0" pButton pRipple type="button" icon="pi pi-trash" (click)="preExcluir(rowData)" class="p-button-rounded p-button-text"></button>

              <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(rowData, rowIndex)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
              <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(rowData, rowIndex)" class="p-button-rounded p-button-text p-button-danger"></button>

              <button *ngIf="isAdding && rowIndex == 0" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowAddSave()" class="p-button-rounded p-button-text p-button-success mr-2"></button>
              <button *ngIf="isAdding && rowIndex == 0" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowAddCancel(rowData, rowIndex)" class="p-button-rounded p-button-text p-button-danger"></button>
            </td>
            <td *ngFor="let col of columns" style="text-align:center; padding: 5px;" class="{{col.class}}">
                <!--ADIÇÃO-->
                <div *ngIf="col.field == 'nomePerfil'">
                  <p-autoComplete *ngIf="isAdding && rowIndex == 0" [(ngModel)]="nomePerfilAdd" [suggestions]="filteredPerfis" (completeMethod)="filterPerfis($event)"
                  field="nomePerfil" [minLength]="1" placeholder="Digite o perfil desejado..."></p-autoComplete>
                </div>
                <div *ngIf="col.field == 'nomeGrupoActiveDirectory'">
                    <input *ngIf="isAdding && rowIndex == 0" pInputText class="form-control" type="text" [(ngModel)]="nomeGrupoActiveDirectoryAdd">
                </div>

                <!--EDIÇÃO-->
                <p-cellEditor *ngIf="col.field == 'nomePerfil'">
                    <ng-template pTemplate="input">
                      <p-autoComplete [(ngModel)]="nomePerfilAdd" [suggestions]="filteredPerfis" (completeMethod)="filterPerfis($event)"
                                      field="nomePerfil" [minLength]="1"></p-autoComplete>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{rowData[col.field] | columnRender : col.type:col.format}}
                    </ng-template>
                </p-cellEditor>

                <p-cellEditor *ngIf="col.field == 'nomeGrupoActiveDirectory'">
                    <ng-template pTemplate="input">
                        <input pInputText class="form-control" type="text" [(ngModel)]="rowData[col.field]">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{rowData[col.field] | columnRender : col.type:col.format}}
                    </ng-template>
                </p-cellEditor>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td class="rowEmpty" [colSpan]="this.table.cols.length+1">
              <p *ngIf="table.records==undefined">Clique em filtrar para realizar uma busca</p>
              <p *ngIf="table.records!=undefined && table.records.length==0">Nenhum registro foi encontrado</p>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
</div>
<app-modal-exclusao [model]="modelExclusao" (deleteEvent)="excluir($event)"></app-modal-exclusao>

