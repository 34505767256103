<div class="disabled-area" *ngIf="this.modalService.excludeVisible">
  <div class="card card-exclude">
    <a class="close" (click)="close()">
      <span></span>
      <span></span>
    </a>
    <div class="content">
      <span class="image-exclude"></span>
      <span class="title mt-auto">Ooops!</span>
      <span class="text mb-auto">
        Tem certeza que deseja excluir esse(a) {{this.model.nome}}?
        Essa ação não pode ser desfeita
      </span>
      <button class="button button_red" (click)="exclude()">EXCLUIR</button>
    </div>
  </div>
</div>
