<div class="select_wrap" [ngClass]="iconsDrodownOpened ? 'active' : ''">
  <ul class="default_option" (click)="toggleIconDropdown()">
    <li>
      <span class="cinza-claro" *ngIf="!selectedIcon">Ícone:</span>
      <img *ngIf="selectedIcon" height="30" width="30" alt="" [src]="dom.bypassSecurityTrustUrl('data:image/svg+xml;base64,'.concat(selectedIcon))">
    </li>
  </ul>
  <div class="select_ul">
    <div *ngFor="let icon of icons" class="{{optionSelected? 'option-selected' : ''}}">
      <input type="checkbox" id="icon-toggle-{{icon.nome}}" class="icon-toggle" hidden>
      <label class="option" for="icon-toggle-{{icon.nome}}" (click)="toggleIcons()">
        <span>{{icon.nome}}</span>
        <span class="setinha"></span>
      </label>
      <div class="div-icons">
        <div class="content-icons m-2">
          <div class="p-2" *ngFor="let imagem of icon.imagens" (click)="selectIcon(imagem.base64)">
            <img height="30" width="30" [src]="dom.bypassSecurityTrustUrl('data:image/svg+xml;base64,'.concat(imagem.base64))" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
