import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalService {
  excludeVisible: boolean = false;
  visualizeVisible: boolean = false;

  show(id: string) {
    const modal = document.getElementById(id);
    if (modal)
      modal.classList.add('d-block');
  }

  hide(id: string) {
    const modal = document.getElementById(id);
    if (modal)
      modal.classList.remove('d-block');
  }

  abrirModalExclusao() {
    this.excludeVisible = true;
  }

  abrirModalVisualizar() {
    this.visualizeVisible = true;
  }
}
