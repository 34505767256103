import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ColaboradorComponent} from './views/colaborador/colaborador.component';
import {AuthGuard} from './_helpers';
import {EscolhaAcessoComponent} from "./views/escolha-acesso/escolha-acesso.component";
import { SemacessoComponent } from './views/semacesso/semacesso.component';
import { CockpitVendasComponent } from './views/cockpit-vendas/cockpit-vendas.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import {ClientesComponent} from "./clientes/clientes.component";
import {IncluirClienteComponent} from "./clientes/incluir-cliente/incluir-cliente.component";
import { IncluirUsuarioComponent } from './usuarios/incluir-usuario/incluir-usuario.component';
import { EditarUsuarioComponent } from './usuarios/editar-usuario/editar-usuario.component';
import {PerfilComponent} from "./perfil/perfil.component";

const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const redefinicaosenhaModule = () => import('./redefinicaosenha/redefinicaosenha.module').then(x => x.RedefinicaoSenhaModule);
const administracaoModule = () => import('./administracao/administracao.module').then(x => x.AdministracaoModule)

const routes: Routes = [
  { path: '', component: EscolhaAcessoComponent, canActivate: [AuthGuard], data: { uf: 'ADM03', funcionalidade: 'Visualizar' }},
  { path: 'account', loadChildren: accountModule },
  { path: 'redefinicaosenha', loadChildren: redefinicaosenhaModule },
  { path: 'colaborador', component: ColaboradorComponent, canActivate: [AuthGuard], data: { uf: 'CKP01', funcionalidade: 'Visualizar' } },
  { path: 'vendas', component: CockpitVendasComponent, canActivate: [AuthGuard], data: { uf: 'CKP02', funcionalidade: 'Visualizar' } },
  { path: 'semacesso', component: SemacessoComponent, canActivate: [AuthGuard] },
  { path: 'administracao', loadChildren: administracaoModule },

  //criados para o HUBHAWK
  { path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuard] },
  { path: 'usuarios/incluir', component: IncluirUsuarioComponent, canActivate: [AuthGuard] },
  { path: 'usuarios/editar', component: EditarUsuarioComponent, canActivate: [AuthGuard] },
  {path:'clientes',component:ClientesComponent,canActivate: [AuthGuard],data:{uf:'CAD01'}},
  {path:'clientes/incluir',component:IncluirClienteComponent,canActivate:[AuthGuard],data:{uf:'CAD01',funcionalidade: 'Visualizar'}},
  {path:'perfil',component:PerfilComponent,canActivate:[AuthGuard],data:{uf:'SEG05',funcionalidade: 'Visualizar'}},

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
