import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DataSourceRequest } from 'src/app/_models/data-source-request';
import { TipoSituacaoMapping } from 'src/app/_models/enums/situacao';
import { FiltroClienteDto } from 'src/app/_models/filtros/filtro-cliente-dto';
import { Usuario } from 'src/app/_models/usuario';
import { AlertService, PermissionService } from 'src/app/_services';
import { ClienteService } from 'src/app/_services/ClienteService';
import { UsuariosService } from 'src/app/_services/usuarios.service';

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html',
  styleUrls: ['./editar-usuario.component.css']
})
export class EditarUsuarioComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
    public permissionService: PermissionService,
    private usuarioService: UsuariosService,
    private clienteService: ClienteService,
    private router: Router,
    private alertService: AlertService) { }

  //enum para dropdown
  situacoes = TipoSituacaoMapping;

  //Variavel Modelo de Usuario Global, vindo do Service
  user;

  //array com lista de Clientes p/ dropdown
  clientes;

  //formulario Adição e Edição
  formUsuario: UntypedFormGroup;

  ngOnInit(): void {
    this.usuarioService.usuarioEDIT.subscribe((value) => {
      this.createFormUsuario(value);
      this.user = value;
    });
    this.getListaClientes();
  }

  getListaClientes() {
    var filtroDto = new FiltroClienteDto;
    filtroDto.request = new DataSourceRequest;
    this.clienteService.listarClientes(filtroDto).subscribe(
      success => {
        this.clientes = success.records;
      }
    )
  }

  createFormUsuario(usuario: Usuario) {
    this.formUsuario = this.formBuilder.group({
      id: [usuario.id],
      nome: [usuario.nome],
      email: [usuario.email],
      telefone: [this.telefoneMaskFull(usuario.telefone)],
      salt: [usuario.salt],
      situacao: [usuario.situacao],
      cliente: [usuario.cliente]
    });
    this.formUsuario.get('situacao')?.disable();
  }

  telefoneMaskFull(telefone: string){
    if(telefone.length == 10){
      var regex = /^(\d{2})(\d{4})(\d{4})$/;
      var resultado = telefone.replace(regex, '($1) $2-$3');
      return resultado;
    } 
    if(telefone.length == 11){
      var regex = /^(\d{2})(\d{5})(\d{4})$/;
      var resultado = telefone.replace(regex, '($1) $2-$3');
      return resultado;
    } 
  }

  alterarUsuario(){
    this.alertService.showProgressBar();
    var obj = this.montarDto(this.formUsuario.value);
    this.usuarioService.alterarUsuario(obj).subscribe(
      success => {
        this.alertService.hideProgressBar();
        this.alertService.success(success.messages[0]);
        setTimeout(() => {
          this.router.navigate(['/usuarios'])
        }, 4000);
      },
      error => {
        this.alertService.hideProgressBar();
        this.alertService.error(error);
      }
    )
  }

  voltar(){
    this.router.navigate(['/usuarios']);
  }

  montarDto(form){
    var user = new Usuario();
    user.id = form.id;
    user.nome = form.nome;
    user.email = form.email;
    user.telefone = form.telefone;
    user.salt = form.salt;
    user.situacao = this.user.situacao;
    user.cliente = form.cliente;
    return user
  }
}
