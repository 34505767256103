import {TipoSituacao} from "./enums/situacao";

export class ViewPerfil{
  id:string;
  uuid:string;
  nome:string;
  situacao:TipoSituacao;
  idResponsavelCriacao:string;
  nomeResponsavelCriacao:string;

  constructor() {
    this.nome = null;
    this.situacao = TipoSituacao.Ativo;
    this.idResponsavelCriacao = null;
    this.nomeResponsavelCriacao = null;
    this.id=null;
  }
}
