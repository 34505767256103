import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Atalho, CategoriaAtalho} from "../../_models/atalho";
import {AtalhoService} from "../../_services/atalho.service";
import {AccountService, AlertService, ModalService, PermissionService} from "../../_services";
import { TableService } from '../../_services/table.service';
import { ColumnType } from '../../_models/enums/column-type';
import { ModalExclusao } from 'src/app/_models/components/modal-exclusao';
import {PaginatorModule} from 'primeng/paginator';
import {ConfirmationService} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {Parametro} from 'src/app/_models/parametro';
import {ParametroService} from 'src/app/_services/parametros.service';
import {ParametroRequest} from 'src/app/_models/parametroRequest';

@Component({
  selector: 'app-atalho',
  templateUrl: './atalho.component.html',
  styleUrls: [
    './atalho.component.css',
    '../administracao.css'],
  providers: [
    TableService,
    ConfirmDialogModule,
    ConfirmationService
  ]
})
export class AtalhoComponent implements OnInit {
  modelExclusao: ModalExclusao;

  //HUBHAWK
  formAtalho: UntypedFormGroup;
  categories = CategoriaAtalho;
  uf;
  modalInclusao: boolean = false;
  modoInclusao: boolean = false;
  atalho;
  acoesTabela;
  constructor(private atalhoService: AtalhoService,
              private alertService: AlertService,
              private modalService: ModalService,
              public permissionService: PermissionService,
              public accountService:AccountService,
              public table: TableService<Atalho> ,
              public fb:UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.getUf(this.accountService.userValue.menu,'ADM01');
    this.createForm(new Atalho());

    this.table.cols = [
      { field: 'nome', header: 'Nome', width: '22,5%' },
      { field: 'descricao', header: 'Descrição', width: '22,5%', class: 'text-truncate' },
      { field: 'url', header: 'Url', width: '22,5%', type: ColumnType.Link },
      { field: 'tipo', header: 'Tipo Cockpit', type: ColumnType.Enum, format: 'TipoCockpit', width: '22,5%' }
    ];
    this.table.setColActionsWidth('10%').withUrlAction2('atalho/listar',this.filtro());
    this.generateAcoesTabela();
  }

  //HUBHAWK
  getUf(base,uf):any{
    if(base==undefined) return {codigo:'',nome:''};
    let data = base.filhos.find(x=>x.codigo==uf);
    if(data !=undefined){
      this.uf= data;
    }
    base.filhos.forEach(x=>{
      return this.getUf(x, uf);
    });
  }
  filtrar(){
    this.table.reloadData2(this.filtro());
  }
  filtro(){
    return {};
  }
  showDialog() {
    this.modalInclusao = true;
  }
  createForm(atalho:Atalho){
    this.formAtalho=this.fb.group({
      id: [atalho.id],
      nome: [atalho.nome, Validators.required],
      descricao: [atalho.descricao, Validators.required],
      url: [atalho.url, Validators.required],
      notificacao: [atalho.notificacao, Validators.required],
      tipo: [atalho.tipo, Validators.required],
      icone: [atalho.icone, Validators.required]
    });
  }
  cancelar() {
    this.resetForm();
  }
  resetForm(){
    this.formAtalho.reset({ 'id': 0, 'notificacao': false });
    this.modoInclusao = true;
    this.modalInclusao=false;
  }
  salvarAtalho(){
    let valido = this.validarAtalho(this.formAtalho.value);
    if(valido) {
      this.atalhoService.salvar(this.formAtalho.value).subscribe(
        res => {
          console.log(res)
          if (res.success) {
            this.alertService.success(res.messages.join('<br/>'));
            this.resetForm();
            this.filtrar();
          } else {
            this.alertService.error(res.messages.join('<br/>'));
          }
        }, error => {
          this.alertService.error(error);
        }
      );
    }
  }
  validarAtalho(atalho){
    let msgs=[];
    if(atalho.nome==null){
      msgs.push('O campo [Nome] é obrigatório.')
    }
    if(atalho.url==null){
      msgs.push('O campo [Url] é obrigatório.')
    }
    if(atalho.descricao==null){
      msgs.push('O campo [Descrição] é obrigatório.')
    }
    if(atalho.tipo==null){
      msgs.push('O campo [Cookpit] é obrigatório.')
    }
    if(atalho.icone==null){
      msgs.push('O campo [Ícone] é obrigatório.')
    }

    this.alertService.error(msgs.join('<br/>'));

    return msgs.length==0;
  }
  opcaoSelecionada(atalho:any){
    this.atalho=atalho;
  }
  preExcluir(model: Atalho) {
    this.modelExclusao = new ModalExclusao(model.id, 'atalho');
    this.modalService.abrirModalExclusao();
  }
  excluir($event) {
    this.atalhoService.excluir($event).subscribe(
      res => {
        this.filtrar();
        this.alertService.success(res.messages.join('<br/>'));
      },
      error => {
        this.alertService.error(error)
      }
    );
  }
  generateAcoesTabela() {
    this.acoesTabela = [
      {label: 'Editar', icon: 'pi pi-pencil', command: (event) => this.editarAtalho(this.atalho),visible:this.permissionService.hasPermission(this.uf.codigo,'Atualizar')},
      {label: 'Excluir', icon: 'pi pi-trash', command: (event) => this.preExcluir(this.atalho),visible:this.permissionService.hasPermission(this.uf.codigo,'Remover')},
    ];
  }
  editarAtalho(atalho){
    this.createForm(atalho);
    this.modalInclusao=true;
  }
  //HUBHAWK

}
