import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { enumDisplay } from '../_helpers/enum-display';
import { ColumnType } from '../_models/enums/column-type';

@Pipe({
  name: 'columnRender'
})
export class ColumnRenderPipe implements PipeTransform {

  transform(value: any, type: ColumnType, format: string) {

    switch (type) {
      case ColumnType.Date:
        return new DatePipe('pt-BR').transform(value, format);
      
      case ColumnType.Enum:
        return enumDisplay[format][value];

      case ColumnType.Link:
        return `<a href="${value}" target="_blank">${value}</a>`;
      
      default:
        return value;
    }
  }
}
