import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalExclusao } from 'src/app/_models/components/modal-exclusao';
import { PerfilSistemaGrupoAd } from 'src/app/_models/perfilSistemaGrupoAd';
import { AccountService } from 'src/app/_services/account.service';
import { AlertService } from 'src/app/_services/alert.service';
import { ModalService } from 'src/app/_services/modal.service';
import { PerfilSistemaGrupoAdService } from 'src/app/_services/perfilsistemagrupoad.service';
import { PermissionService } from 'src/app/_services/permission.service';
import { TableService } from 'src/app/_services/table.service';

@Component({
  selector: 'app-perfil-sistema-grupo-ad',
  templateUrl: './perfil-sistema-grupo-ad.component.html',
  styleUrls: [ './perfil-sistema-grupo-ad.component.css' ],
  providers: [TableService]
})
export class PerfilSistemaGrupoAdComponent implements OnInit {

  constructor(public modalService: ModalService,
              public alertService: AlertService,
              public perfilSistemaGrupoAdService: PerfilSistemaGrupoAdService,
              public accountService: AccountService,
              public permissionService: PermissionService,
              public table: TableService<any>) {
                this.accountService.user.subscribe(x => this.user = x);
  }

  modelExclusao;
  sistemas;
  selectedSistema;
  user;
  rows = 10;
  first = 1;
  isAdding = false;
  loading = false;
  clonedPerfil: { [s: string]: PerfilSistemaGrupoAd; } = {};
  clonedPerfilAdd = [];
  perfilSistemaGrupoAd = new PerfilSistemaGrupoAd();
  filteredPerfis;
  nomePerfilAdd;
  nomeGrupoActiveDirectoryAdd;
  perfil = new PerfilSistemaGrupoAd();
  loadingExportarTodos = false;
  loadingExportarPagina = false;
  @ViewChild('incluirPerfil') incluirPerfilRef: ElementRef;

  ngOnInit(): void {
    this.perfilSistemaGrupoAdService.listarSistemas().subscribe(
      success => {
        this.sistemas = success.data;
        this.sistemas.forEach(sistema => {
          if(sistema.id == 1){
            this.selectedSistema = sistema;
          }
        });
      }
    )
    this.table.cols = [
      { field: 'nomePerfil', header: 'Perfil', width: '40%' },
      { field: 'nomeGrupoActiveDirectory', header: 'Grupo Active Directory', width: '40%' }
    ];
    this.table.setColActionsWidth('10%').withUrlAction(`perfilsistemagrupoad/1`);
  }

  filterPerfis(event){
    this.perfilSistemaGrupoAdService.listarPerfis(event.query).subscribe(
      success => {
        this.filteredPerfis = success.data.records;
      }
    )
  }

  changeSistema(event){
    this.selectedSistema = event.value;
    this.table.withUrlAction(`perfilsistemagrupoad/${event.value.id}`);
    this.table.reloadData();
  }

  exportarTodos(){
    this.loading = true;
    this.table.request.pageSize = this.table.total;
    var idSistema = this.selectedSistema.id;
    this.table.exportar(`perfilsistemagrupoad/exportar/${idSistema}`).add(() => {
      this.loading = false;
    });
  }

  exportarPagina(){
    this.loading = true;
    this.table.request.pageSize = 10;
    var idSistema = this.selectedSistema.id;
    this.table.exportar(`perfilsistemagrupoad/exportar/${idSistema}`).add(() => {
      this.loading = false;
    });
  }

  addPerfilSistemaGrupoAd(){
    //desabilita o botao de incluir
    this.incluirPerfilRef.nativeElement.disabled = true;
    //reset os valores dos campos de adição
    this.nomePerfilAdd = '';
    this.nomeGrupoActiveDirectoryAdd = '';
    //começa a ação de adição
    this.isAdding = true;
    //clona a lista de elementos da tabela
    this.clonedPerfilAdd = this.table.records;
    //insere os campos de adição na primeira linha da tabela
    this.clonedPerfilAdd.unshift(this.perfil);
  }

  onRowAddCancel(){
    //reset os valores dos campos de adição
    this.nomePerfilAdd = '';
    this.nomeGrupoActiveDirectoryAdd = '';
    //habilita o botao de incluir
    this.incluirPerfilRef.nativeElement.disabled = false;
    //cancela a ação de adição
    this.isAdding = false;
    //remove o primeiro elemento da tabela
    this.table.records.shift();
  }

  onRowAddSave(){
    this.loading = true;
    var obj = {
      idPerfil: this.nomePerfilAdd.idPerfil,
      nomeGrupoActiveDirectory: this.nomeGrupoActiveDirectoryAdd,
      idSistema: this.selectedSistema?.id
    }; console.log(obj)
    this.perfilSistemaGrupoAdService.incluirPerfilSistemaGrupoAd(obj).subscribe(
      success =>{
        this.alertService.success(success.messages[0]);
        this.incluirPerfilRef.nativeElement.disabled = false;
        this.isAdding = false;
        this.loading = false;
        this.table.reloadData();
      },
      error => {
        this.alertService.error(error);
        this.incluirPerfilRef.nativeElement.disabled = false;
        this.isAdding = false;
        this.loading = false;
        this.table.records.shift();
      }
    )
  }

  preExcluir(model) {
    this.modelExclusao = new ModalExclusao(model.id, 'Perfil Grupo AD');
    this.modalService.abrirModalExclusao();
  }

  excluir($event) {
    this.loading = true;
    this.perfilSistemaGrupoAdService.excluirPerfilSistemaGrupoAd($event).subscribe(
      res => {
        this.loading = false;
        this.table.reloadData();
        this.alertService.success(res.messages[0]);
      },
      error => {
        this.loading = false;
        this.alertService.error(error);
      }
    );
  }

  onRowEditInit(perfilSistemaGrupoAd : PerfilSistemaGrupoAd) {
    this.nomePerfilAdd = perfilSistemaGrupoAd;
    this.clonedPerfil[perfilSistemaGrupoAd.id] = {...perfilSistemaGrupoAd};
  }

  onRowEditCancel(perfilSistemaGrupoAd: PerfilSistemaGrupoAd, index: number) {
    this.table.records[index] = this.clonedPerfil[perfilSistemaGrupoAd.id];
    delete this.clonedPerfil[perfilSistemaGrupoAd.id];
  }

  onRowEditSave(perfilSistemaGrupoAd: PerfilSistemaGrupoAd, index: number) {
    if(this.nomePerfilAdd.idPerfil == null || this.nomePerfilAdd.idPerfil == undefined){
      this.alertService.error("Preencha o campo com um perfil válido");
      return;
    }
    var obj = {
      id: perfilSistemaGrupoAd.id,
      idPerfil: this.nomePerfilAdd.idPerfil,
      idSistema: perfilSistemaGrupoAd.idSistema,
      nomeGrupoActiveDirectory: perfilSistemaGrupoAd.nomeGrupoActiveDirectory
    };

    this.perfilSistemaGrupoAdService.atualizarPerfilSistemaGrupoAd(obj, perfilSistemaGrupoAd.id).subscribe(
      success =>{
        this.alertService.success(success.messages[0]);
        this.table.reloadData();
      },
      error => {
        this.alertService.error(error);
        this.table.records[index] = this.clonedPerfil[perfilSistemaGrupoAd.id];
        delete this.clonedPerfil[perfilSistemaGrupoAd.id];
      }
    )
  }

  //paginator
  paginate(event) {
    this.table.changePage(event.rows, event.first)
    this.rows = event.rows;
    this.first = event.first;
  }
  refresh() {
    //this.table.request.cleanFilter();
    this.table.reloadData();
  }
}
