<div class="container-fluid">
  <div class="row no-gutters">
    <h2 class="col-12">{{uf.nome}}</h2>
    <span class="subtitle">Realize uma busca com os campos abaixo e exporte para o Excel</span>
  </div>
  <div class="row no-gutters">
    <form [formGroup]="formSeguranca" style="width: 100%;" (ngSubmit)="buscar()">
      <div class="form-group content">
        <br>
        <div class="row">
          <div class="col-3">
            <span class="p-float-label">
              <p-calendar id="float-input" formControlName="dataInicial" [showIcon]="true" dateFormat="dd/mm/yy"></p-calendar>
              <label for="float-input">Data inicial</label>
            </span> 
          </div>
          <div class="col-3">
            <span class="p-float-label">
              <p-calendar id="float-input" formControlName="dataFinal" [showIcon]="true" dateFormat="dd/mm/yy"></p-calendar>
              <label for="float-input">Data final</label>
            </span> 
          </div>
          <div class="col-6">
            <span class="p-float-label">
              <input id="float-input" pInputText type="text" class="form-control" formControlName="nome">
              <label for="float-input">Nome completo</label>            
            </span>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-3">
            <span class="p-float-label">
              <input id="float-input" pInputText type="text" class="form-control" formControlName="email">
              <label for="float-input">E-mail</label>            
            </span>
          </div>
          <div class="col-3">
            <span class="p-float-label">
              <input id="float-input" pInputText type="text" class="form-control" formControlName="login">
              <label for="float-input">Login</label>            
            </span>
          </div>
          <div class="col-6">
            <div class="flexEnd">
              <button class="p-button-primary" icon="pi pi-search" label="Filtrar" pButton
                      (click)="buscar()" type="button"></button>
              <button class="p-button-secondary" icon="pi pi-times" label="Limpar" pButton
                      (click)="refresh()" type="button" style="margin-left: 2%;"></button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <br>
  <div class="row">
    <div class="col-9">
      <h4 class="font-weight-semi-bold">Report Audit Access User</h4>
    </div>
    <div class="col-3 flexEnd">
      <button class="p-button-info" icon="pi pi-file-excel" label="Exportar" pButton
                    (click)="exportar()" type="button">
        <span *ngIf="loadingExportar" class="spinner-border spinner-border-sm mr-1"></span>       
      </button>
    </div>
  </div>
  <p-table 
            responsiveLayout="scroll" 
            [(first)]="this.table.request.start" 
            class="col-12" styleClass="p-datatable-gridlines p-datatable-striped"
            [lazy]="true"
            [columns]="table.cols" 
            [value]="table.records" 
            (onLazyLoad)="this.table.init($event)" 
            [loading]="table.loading"
            [showCurrentPageReport]="true"
            [paginator]="true"
            [rows]=table.rowPerPage[0]
            [rowsPerPageOptions]=table.rowPerPage
            [totalRecords]="table.total" 
            sortField="atualizadoEm" 
            [sortOrder]="-1">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" [ngStyle]="{'width': col.width}">
          <p-sortIcon field="{{col.field}}"></p-sortIcon>
          {{col.header}}
        </th>
        <th style="text-align:center" [ngStyle]="{'width': table.colActionsWidth}">Ações</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns" class="text-center {{col.class}}"
          [innerHtml]="rowData[col.field] | columnRender : col.type:col.format"></td>
        <td class="text-center col-actions">
          <button pButton pRipple icon="pi pi-eye" title="Visualizar" (click)="visualizar(rowData)"
            class="p-element p-ripple p-button-rounded p-button-primary p-button-text p-button p-component p-button-icon-only m-r-2">
          </button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td class="rowEmpty" [colSpan]="this.table.cols.length+1">
          <p *ngIf="table.records==undefined">Clique em filtrar para realizar uma busca</p>
          <p *ngIf="table.records!=undefined && table.records.length==0">Nenhum registro foi encontrado</p>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <app-modal-visualizacao [model]="modelVisualizacao"></app-modal-visualizacao>
</div>
