export const PRIMENG_PT_BR = {
  startsWith: "Começa com",
  contains: "Contém",
  notContains: "Não contém",
  endsWith: "Termina com",
  equals: "Igual a",
  notEquals: "Diferente de",
  noFilter: "Sem filtro",
  lt: "Menor que",
  lte: "Menor ou igual a",
  gt: "Maior que",
  gte: "Maior ou igual a",
  is: "É",
  isNot: "Não é",
  before: "Antes",
  after: "Depois",
  dateIs: "Data é",
  dateIsNot: "Data não é",
  dateBefore: "Data é antes",
  dateAfter: "Date é depois",
  clear: "Limpar",
  apply: "Aplicar",
  matchAll: "Igual a todos",
  matchAny: "Igual a nenhum",
  addRule: "Adicionar regra",
  removeRule: "Remover regra",
  accept: "Sim",
  reject: "Não",
  choose: "Escolha",
  upload: "Upload",
  cancel: "Cancelar",
  dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
  dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
  dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
  monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
  monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
  dateFormat: "dd/mm/yy",
  today: "Hoje",
  weekHeader: "Sem",
  weak: "Fraca",
  medium: "Média",
  strong: "Forte",
  passwordPrompt: "Digite uma senha",
  emptyMessage: "Nenhum resultado encontrado",
  emptyFilterMessage: "Nenhum resultado encontrado"
}
