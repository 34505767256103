import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IConfig } from 'ngx-mask';
import {AppRoutingModule} from './app-routing.module';
import { BasicAuthInterceptor, ErrorInterceptor } from './_helpers';
import {AppComponent} from './app.component';
import { AlertComponent } from './_components';
import { EscolhaAcessoComponent } from './views/escolha-acesso/escolha-acesso.component';
import { ColaboradorComponent } from './views/colaborador/colaborador.component';
import { SplitPipe } from './_pipes/split.pipe';
import { HeaderComponent } from './_components/header/header.component';
import { SidebarComponent } from './_components/sidebar/sidebar.component';
import { SemacessoComponent } from './views/semacesso/semacesso.component';
import { SidebarModule } from 'primeng/sidebar';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { GreetingsPipe } from './_pipes/greetings.pipe';
import { CockpitVendasComponent } from './views/cockpit-vendas/cockpit-vendas.component';
import { CardsAtalhosComponent } from './_components/cards-atalhos/cards-atalhos.component';
registerLocaleData(localePt);

//adicionados HUBHAWK
import { UsuariosComponent } from './usuarios/usuarios.component';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { SplitButtonModule } from 'primeng/splitbutton';
import { IncluirUsuarioComponent } from './usuarios/incluir-usuario/incluir-usuario.component';
import { EditarUsuarioComponent } from './usuarios/editar-usuario/editar-usuario.component';
import { InputMaskModule } from 'primeng/inputmask';
import { PasswordModule } from 'primeng/password';
import { ClientesComponent } from './clientes/clientes.component';
import { IncluirClienteComponent } from './clientes/incluir-cliente/incluir-cliente.component';
import { ProgressBarModule } from "primeng/progressbar";
import { TooltipModule } from 'primeng/tooltip';
import { SlideMenuModule } from "primeng/slidemenu";
import { PerfilComponent } from './perfil/perfil.component';
import {AdministracaoModule} from "./administracao/administracao.module";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    SidebarModule,
    CommonModule,

    //adicionados HUBHAWK
    FormsModule,
    DropdownModule,
    DialogModule,
    TableModule,
    PaginatorModule,
    ButtonModule,
    InputTextModule,
    AutoCompleteModule,
    MultiSelectModule,
    SplitButtonModule,
    InputMaskModule,
    PasswordModule,
    ProgressBarModule,
    TooltipModule,
    SlideMenuModule,
    AdministracaoModule
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    EscolhaAcessoComponent,
    ColaboradorComponent,
    SplitPipe,
    HeaderComponent,
    SidebarComponent,
    SemacessoComponent,
    GreetingsPipe,
    CockpitVendasComponent,
    CardsAtalhosComponent,

    //adicionados HUBHAWK
    UsuariosComponent,
    IncluirUsuarioComponent,
    EditarUsuarioComponent,
    ClientesComponent,
    IncluirClienteComponent,
    PerfilComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: "pt-BR"},
    [DatePipe]
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
