<div class="content pb-5 escolha-acesso-view">
  <div class="container align-self-center">
    <div class="div-top"></div>
    <div class="div-mid item-center col-md-12 p-0">
      <div class="div-left-mid h-100"></div>
      <div class="div-center-mid item-center h-100">
        <label>Escolha o acesso:</label>
      </div>
      <div class="div-right-mid h-100"></div>
    </div>
    <div class="div-bottom d-flex justify-content-between">
      <div class="card d-flex align-items-center {{permissionService.hasPermission('CKP01', 'Visualizar')? '' : 'off'}}" routerLink="/colaborador">
        <img src="../../../assets/img/icon-colaborador.png" alt="" >
        <div class="card-body">
          <p>Colaborador</p>
        </div>
      </div>
      <div class="card d-flex align-items-center {{permissionService.hasPermission('CKP02', 'Visualizar')? '' : 'off'}}" routerLink="/vendas">
        <img src="../../../assets/img/icon-cockpit.png" alt="">
        <div class="card-body text-center">
          <p class="mb-0">Cockpit de vendas</p>
        </div>
      </div>
    </div>
  </div>
</div>
