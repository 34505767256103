export enum TipoSituacao {
  Ativo = 1,
  Suspenso = 2,
  Cancelado = 3
}
export const TipoSituacaoMapping = [
  { value: TipoSituacao.Ativo, nome: "Ativo"},
  { value: TipoSituacao.Suspenso, nome: "Suspenso"},
  { value: TipoSituacao.Cancelado, nome: "Cancelado"}
]
  
  export const TipoSituacaoList = Object.keys(TipoSituacao)
    .filter((v) => isNaN(Number(v)))
    .map((name) => {
      return {
        name,
        code: TipoSituacao[name as keyof typeof TipoSituacao]
      };
    });
  