import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {environment} from '../../environments/environment';
import {BaseResponse, DataSourceResponse} from "../_models";
import {Atalho} from "../_models/atalho";
import {DataSourceRequest} from "../_models/data-source-request";
import HttpClientUtils from "../_helpers/http-client-utils";

@Injectable({providedIn: 'root'})
export class AtalhoService {
  constructor(private http: HttpClient) {
  }

  salvar(model: Atalho) {
    if (model.id) {
      return this.http.put<BaseResponse<Atalho>>(`${environment.apiUrl}/atalho`, model);
    }
    return this.http.post<any>(`${environment.apiUrl}/atalho`, model);
  }

  loadAtalhos(tipoCockpit) {
    const request = new DataSourceRequest()
      .addFilter('tipo', tipoCockpit).setOrder('nome', 'asc');
    const httpParams = HttpClientUtils.toHttpParams(request);

    return this.http.get<BaseResponse<DataSourceResponse<Atalho>>>(`${environment.apiUrl}/atalho`, {params: httpParams});
  }

  excluir(idAtalho: number) {
    return this.http.delete<BaseResponse<any>>(`${environment.apiUrl}/atalho/${idAtalho}`);
  }
}
