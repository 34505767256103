import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BaseResponse, DataSourceResponse } from '../_models';
import { environment } from 'src/environments/environment';
import { Sistema } from '../_models/sistema';

@Injectable({providedIn: 'root'})
export class PermissoesService {
  constructor(private http: HttpClient) {}

  listarUnidadesFuncionais(idPerfil, cdSistema, ufOuFuncionalidade?) {
    ufOuFuncionalidade == undefined ? ufOuFuncionalidade = "" : ufOuFuncionalidade = ufOuFuncionalidade;
    return this.http.get<BaseResponse<DataSourceResponse<any>>>(`${environment.apiUrl}/permissao?idPerfil=${idPerfil}&query=${ufOuFuncionalidade}&cdSistema=${cdSistema}`); 
  }                         

  listarPerfisPorSistema(idSistema?){
    return this.http.get<BaseResponse<DataSourceResponse<any>>>(`${environment.apiUrl}/perfil/sistema?idSistema=${idSistema}`); 
  }

  listarPerfis(query){
    return this.http.get<BaseResponse<DataSourceResponse<any>>>(`${environment.apiUrl}/perfil?chaveBusca=${query}&situacoes=1&incluirSuspensos=false`);
  }

  listarSistemas(){
    return this.http.get<BaseResponse<DataSourceResponse<Sistema>>>(`${environment.apiUrl}/sistema/listar`); 
  }

  concederPermissao(idPerfil, idFuncionalidade){
    return this.http.post<BaseResponse<DataSourceResponse<any>>>(`${environment.apiUrl}/permissao/perfil/${idPerfil}/funcionalidade/${idFuncionalidade}`, {idPerfil, idFuncionalidade}); 
  }

  removerPermissao(idPerfil, idFuncionalidade){
    return this.http.delete<BaseResponse<DataSourceResponse<any>>>(`${environment.apiUrl}/permissao/profile/${idPerfil}/functionality/${idFuncionalidade}`); 
  }
}
