import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {environment} from '../../environments/environment';
import {BaseResponse} from "../_models";
import {Notificacao} from "../_models/notificacao";
import HttpClientUtils from '../_helpers/http-client-utils';

@Injectable({providedIn: 'root'})
export class MinhaNotificacaoService {
  showNotifications: boolean = false;

  constructor(private http: HttpClient) {
  }
  
  loadMinhasNotificacoes() {    
    return this.http.get<BaseResponse<Notificacao[]>>(`${environment.apiUrl}/minha/notificacao`);
  }

  marcarLida(chave:any){
    const httpParams = HttpClientUtils.toHttpParams(chave);  
    return this.http.post<BaseResponse<string>>(`${environment.apiUrl}/minha/notificacao/` + chave, {params: httpParams});
  }
}
