import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BaseResponse, DataSourceResponse } from '../_models';
import { environment } from 'src/environments/environment';
import { Sistema } from '../_models/sistema';
import { DataSourceRequest } from '../_models/data-source-request';
import HttpClientUtils from '../_helpers/http-client-utils';

@Injectable({providedIn: 'root'})
export class PerfilSistemaGrupoAdService {
  constructor(private http: HttpClient) {}

/*
  listarPerfisPorSistema(codSistema?){
    let dataRequest = new DataSourceRequest();
    dataRequest.addFilter('situacao', 1);
    const params = HttpClientUtils.toHttpParams(dataRequest);
    //return this.http.get<BaseResponse<any>>(`${environment.apiUrl}/praca`, {params});
    return this.http.get<BaseResponse<DataSourceResponse<any>>>(`${environment.apiUrl}/perfil/sistema/listar?cdSistema=${codSistema}`); 
  } */

/*   listarPerfisPorSistema(codSistema){
    return this.http.get<BaseResponse<DataSourceResponse<Sistema>>>(`${environment.apiUrl}/perfil/sistema?codSistema=${codSistema}`); 
  } */

  listarPerfis(query){
    return this.http.get<BaseResponse<DataSourceResponse<any>>>(`${environment.apiUrl}/perfil?chaveBusca=${query}&situacoes=1&incluirSuspensos=false`);
  }

  incluirPerfilSistemaGrupoAd(perfilSistemaGrupoAd){
    return this.http.post<BaseResponse<DataSourceResponse<any>>>(`${environment.apiUrl}/perfilsistemagrupoad`, perfilSistemaGrupoAd);
  }

  atualizarPerfilSistemaGrupoAd(perfilSistemaGrupoAd, id){
    return this.http.put<BaseResponse<DataSourceResponse<any>>>(`${environment.apiUrl}/perfilsistemagrupoad/${id}`, perfilSistemaGrupoAd);
  }

  excluirPerfilSistemaGrupoAd(id){
    return this.http.delete<BaseResponse<DataSourceResponse<any>>>(`${environment.apiUrl}/perfilsistemagrupoad/${id}`);
  }

  listarPerfisSistemaGrupoAd(idSistema){
    return this.http.get<BaseResponse<DataSourceResponse<any>>>(`${environment.apiUrl}/perfilsistemagrupoad/${idSistema}`);
  }

  listarSistemas(){
    return this.http.get<BaseResponse<DataSourceResponse<Sistema>>>(`${environment.apiUrl}/sistema/listar`); 
  }
  
}
