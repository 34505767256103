import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AlertService, ModalService, PermissionService} from "../../_services";
import {CategoriaNotificacao, Notificacao} from "../../_models/notificacao";
import {NotificacaoService} from "../../_services/notificacao.service"
import {ColumnType} from '../../_models/enums/column-type';
import {TableService} from '../../_services/table.service';
import {ModalExclusao} from '../../_models/components/modal-exclusao';
import {ModalVisualizacao} from '../../_models/components/modal-visualizacao';

@Component({
  selector: 'app-notificacao',
  templateUrl: './notificacao.component.html',
  styleUrls: [
    './notificacao.component.css',
    '../administracao.css'],
  providers: [
    TableService
  ]
})
export class NotificacaoComponent implements OnInit {
  formNotificacao: UntypedFormGroup;
  categories = CategoriaNotificacao;
  selectedCategorieCode: number;
  loading: boolean = false;
  modelExclusao: ModalExclusao;
  modelVisualizacao: ModalVisualizacao;
  conteudo: string[];
  rows = 10;
  first = 1;

  constructor(private formBuilder: UntypedFormBuilder, private alertService: AlertService,
              private notificacaoService: NotificacaoService,
              private modalService: ModalService,
              public permissionService: PermissionService,
              public table: TableService<Notificacao>) {
  }

  ngOnInit(): void {
    this.createForm(new Notificacao());
    this.table.request.addFilter('categoria', null);
    this.table.cols = [
      {field: 'criadoEm', header: 'Data Criação', width: '15%', type: ColumnType.Date, format: 'dd/MM/yyyy'},
      {field: 'titulo', header: 'Título', width: '15%'},
      {field: 'texto', header: 'Preview do texto', width: '30', class: 'text-truncate'},
      {field: 'categoria', header: 'Categoria', type: ColumnType.Enum, format: 'TipoCategoria', width: '25%'}
    ];
    this.table.setColActionsWidth('15%').withUrlAction('notificacao');
  }

  createForm(notificacao: Notificacao) {
    this.formNotificacao = this.formBuilder.group({
      titulo: [notificacao.titulo, Validators.required],
      texto: [notificacao.texto, Validators.required],
      categoria: [notificacao.categoria, Validators.required]
    })
  }

  onSubmit() {
    this.loading = true;
    this.notificacaoService.incluir(this.formNotificacao.value).subscribe(
      sucess => {
        this.formNotificacao.reset();
        this.alertService.success(sucess.messages[0]);
        this.table.reloadData();
      },
      error => this.alertService.error(error)
    ).add(() => {
      this.loading = false
    });
  }

  visualizar(model: Notificacao) {
    this.conteudo = [
      model.texto
    ]
    this.modelVisualizacao = new ModalVisualizacao({titulo: model.titulo, content: this.conteudo});
    this.modalService.abrirModalVisualizar();
  }

  preExcluir(model: Notificacao) {
    var modelExclusao = new ModalExclusao(model.id, 'notificação');
    this.modelExclusao = modelExclusao;
    this.modalService.abrirModalExclusao();
  }

  excluir($event) {
    this.notificacaoService.excluir($event).subscribe(
      res => {
        this.table.reloadData();
      },
      error => {
        this.alertService.error(error)
      }
    );
  }

  //paginator
  paginate(event) {
    this.table.changePage(event.rows, event.first)
    this.rows = event.rows;
    this.first = event.first;
  }

  refresh() {
    //this.table.request.cleanFilter();
    this.table.reloadData();
  }
}
