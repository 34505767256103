import { Component, OnInit } from '@angular/core';
import {AlertService} from "../../_services";
import { Atalho } from '../../_models/atalho';
import { AtalhoService } from '../../_services/atalho.service';

@Component({
  selector: 'app-colaborador',
  templateUrl: './colaborador.component.html',
  styleUrls: ['./colaborador.component.css']
})
export class ColaboradorComponent implements OnInit {
  atalhos: Atalho[] = null;

  constructor(private atalhoService: AtalhoService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.loadAtalhos();
  }

  loadAtalhos() {
    this.atalhoService.loadAtalhos(1).subscribe(
      data =>
      {
        this.atalhos = data.data.records;      
      },
      error =>
      {
        this.alertService.error(error);
      });
  }
}
