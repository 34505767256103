import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BaseResponse, DataSourceResponse } from '../_models';
import { PerfilPermissao } from '../_models/perfilPermissao';
import { environment } from 'src/environments/environment';
import { Sistema } from '../_models/sistema';
import { Parametro } from '../_models/parametro';

@Injectable({providedIn: 'root'})
export class ParametroService {
  constructor(private http: HttpClient) {}


  listarSistema(){
    return this.http.get<any>(`${environment.apiUrl}/sistema/listar`);
  }

  alterarParametro(model: Parametro){
    return this.http.put<any>(`${environment.apiUrl}/parametro`, model);
  }

  removerParametro(id){
    return this.http.delete<any>(`${environment.apiUrl}/parametro/` + id);
  }

  incluirParametro(param){
    return this.http.post<any>(`${environment.apiUrl}/parametro`, param);
  }

  selecionarParametroSistema(cdSistema){
    return this.http.get<any>(`${environment.apiUrl}/parametro/sistema?codigoSistema=${cdSistema}`);
  }

  alterarParametroSistema(model){
    return this.http.put<any>(`${environment.apiUrl}/parametro/sistema`, model);
  }

  listarSistemas(){
    return this.http.get<BaseResponse<DataSourceResponse<Sistema>>>(`${environment.apiUrl}/sistema/listar`);
  }

}
