<div class="container-fluid">
  <div class="mb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/">Portal</a></li>
        <li class="breadcrumb-item"><a routerLink="/administracao">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Notificações</li>
      </ol>
    </nav>
  </div>
  <div class="mb-5">
    <div class="header">
      <p class="title">Nova Notificação</p>
      <span class="subtitle">Preencha os campos para criar uma nova notificação</span>
    </div>
    <form class="content" [formGroup]="formNotificacao" (ngSubmit)="onSubmit()">
      <div class="grid-left">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Título da notificação:" formControlName="titulo">
        </div>
        <div class="form-group">
          <textarea rows="3" class="form-control" placeholder="Texto:" formControlName="texto" maxlength="4000"></textarea>
        </div>
      </div>
      <div class="grid-right">
        <div class="form-group p-fluid">
          <p-dropdown [options]="categories" [(ngModel)]="selectedCategorieCode" optionLabel="name" optionValue="code" formControlName="categoria" placeholder="Categoria:"></p-dropdown>
        </div>
      </div>
      <div class="grid-button">
        <button type="submit"  class="button button_red" [disabled]="formNotificacao.invalid">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          CRIAR NOTIFICAÇÃO
        </button>
      </div>
    </form>
  </div>

 <div *ngIf="permissionService.hasPermission('ADM01B', 'Listar')">
   <h4 class="font-weight-semi-bold">Últimas notificações criadas</h4>
    <p-table [columns]="table.cols" [value]="table.records" [lazy]="true" (onLazyLoad)="this.table.init($event)" responsiveLayout="scroll" [(first)]="this.table.request.start"
             [paginator]="false" [totalRecords]="table.total" [loading]="table.loading" sortField="criadoEm" [sortOrder]="-1">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" [ngStyle]="{'width': col.width}">
            <p-sortIcon field="{{col.field}}"></p-sortIcon>
              {{col.header}}
          </th>
          <th style="text-align:center" [ngStyle]="{'width': table.colActionsWidth}">Ações</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns" class="{{col.class}}" [innerHtml]="rowData[col.field] | columnRender : col.type:col.format"></td>
          <td class="text-center col-actions">
            <button pButton pRipple icon="pi pi-eye" title="Visualizar" (click)="visualizar(rowData)"
                    class="p-element p-ripple p-button-rounded p-button-primary p-button-text p-button p-component p-button-icon-only m-r-2">
            </button>
            <button pButton pRipple icon="pi pi-trash" *ngIf="permissionService.hasPermission('ADM01B', 'Remover')" title="Excluir" (click)="preExcluir(rowData)"
                    class="p-element p-ripple p-button-rounded p-button-primary p-button-text p-button p-component p-button-icon-only">
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="row">
      <div class="col-1" style="padding-top: 10px;">
        <p-button icon="pi pi-refresh" (onClick)="refresh()"></p-button>
      </div>
      <div class="col-10">
        <p-paginator (onPageChange)="paginate($event)" [rows]="10" [totalRecords]="table.total"
                     [rowsPerPageOptions]="[10,25,50,100]"></p-paginator>
      </div>
    </div>
    <app-modal-visualizacao [model]="modelVisualizacao"></app-modal-visualizacao>
    <app-modal-exclusao [model]="modelExclusao" (deleteEvent)="excluir($event)"></app-modal-exclusao>
  </div>
</div>
