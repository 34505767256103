import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ColumnType } from '../_models/enums/column-type';
import { TipoSituacao, TipoSituacaoMapping } from '../_models/enums/situacao';
import { Usuario } from '../_models/usuario';
import { AccountService, AlertService, PermissionService } from '../_services';
import { TableService } from '../_services/table.service';
import { UsuariosService } from '../_services/usuarios.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: [
    './usuarios.component.css',
  ],
  providers: [
    TableService
  ]
})

export class UsuariosComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
              public accountService: AccountService,
              public permissionService: PermissionService,
              private usuarioService: UsuariosService,
              public table: TableService<Usuario>,
              private router: Router,
              private alertService: AlertService) { }
  
  uf;
  //filtros
  sistemaSelecionado;
  usuario;
  nome;
  email;
  telefone;
  situacao = [TipoSituacao.Ativo]
  
  //enum para dropdown
  situacoes = TipoSituacaoMapping;

  //array com lista de Sistemas p/ dropdown
  sistemas = [{nome: "Sistema"}];

  //opções SplitButton
  acoesTabela: MenuItem[];

  //Informações da Linha da Tabela
  rowData;

  //controle de visibilidade do modal de Senha
  modalSenhaVisibility = false;

  //variavel para controle de Senha
  novaSenha;

  ngOnInit(): void {
    this.getUf(this.accountService.userValue.menu,'SEG11');
    this.generateAcoesTabela();
    this.table.cols = [
      {field: 'nome', header: 'Nome', width: '25%'},
      {field: 'email', header: 'E-mail', width: '20%'},
      {field: 'telefone', header: 'Telefone', width: '15%'},
      {field: 'cliente', header: 'Cliente', width: '20%'},
      {field: 'situacao', header: 'Situação', type: ColumnType.Enum, format: 'TipoSituacao', width: '12%'}
    ];
    this.table.setColActionsWidth('10%').withUrlAction('usuario?situacoes=1');
    console.log(this.table)
  }

  telefoneMask(usuario){
    if(usuario.telefone){
      if(usuario.telefone.length == 10){
        var regex = /^(\d{2})(\d{4})(\d{4})$/;
        var resultado = usuario.telefone.replace(regex, '($1) $2-$3');
        return resultado;
      }
      if(usuario.telefone.length == 11){
        var regex = /^(\d{2})(\d{5})(\d{4})$/;
        var resultado = usuario.telefone.replace(regex, '($1) $2-$3');
        return resultado;
      }
    }
  }

  generateAcoesTabela() {
    this.acoesTabela = [
      {label: 'Editar', icon: 'pi pi-user-edit', command: () => { this.editar() }},      
      {label: 'Ativar', icon: 'pi pi-check-circle', command: () => { this.ativarUsuario() }},
      {label: 'Suspender', icon: 'pi pi-minus-circle', command: () => { this.suspenderUsuario() }},
      {label: 'Cancelar', icon: 'pi pi-times-circle', command: () => { this.cancelarUsuario() }},
      {label: 'Gerar Nova Senha', icon: 'pi pi-lock', command: () => { this.gerarNovaSenha() }}
    ];
  }

  getRowData(data){
    this.rowData = data;
  }

  incluirUsuario(){
    this.router.navigate(['/usuarios/incluir']);
  }

  buscar(){
    this.table.withUrlAction('usuario');
    this.telefone = this.usuarioService.removeTelefoneMask(this.telefone);
    this.table.loading = true;
    this.usuarioService.listarUsuariosNew(this.nome, this.email, this.telefone, this.situacao).subscribe(
      success => {
        if(success.data.records.length > 10) { 
          success.data.records.splice(10, success.data.records.length); 
        }
        this.table.records = success.data.records;
        this.table.loading = false;
        this.table.total = success.data.total;
      },
      error => {
        this.alertService.error(error);
      }
    )
  }

  limparFiltro(){
    this.table.withUrlAction('usuario');
    this.nome = '';
    this.email = '';
    this.telefone = '';
    this.situacao = [];
  }

  editar(){
    this.usuarioService.setUsuario(this.rowData);
    this.router.navigate(['/usuarios/editar']);
  }

  ativarUsuario(){
    this.alertService.showProgressBar();
    this.usuarioService.ativarUsuario(this.rowData.id).subscribe(
      success => {
        this.alertService.hideProgressBar();
        this.alertService.success(success.messages[0]);
        this.table.reloadData();
        this.situacao = [TipoSituacao.Ativo];
      },
      error => {
        this.alertService.hideProgressBar();
        this.alertService.error(error);
      }
    )
  }

  suspenderUsuario(){
    this.alertService.showProgressBar();
    this.usuarioService.suspenderUsuario(this.rowData.id).subscribe(
      success => {
        this.alertService.hideProgressBar();
        this.alertService.success(success.messages[0]);
        this.table.reloadData();
        this.situacao = [TipoSituacao.Ativo];
      },
      error => {
        this.alertService.hideProgressBar();
        this.alertService.error(error);
      }
    )
  }

  cancelarUsuario(){
    this.alertService.showProgressBar();
    this.usuarioService.cancelarUsuario(this.rowData.id).subscribe(
      success => {
        this.alertService.hideProgressBar();
        this.alertService.success(success.messages[0]);
        this.table.reloadData();
        this.situacao = [TipoSituacao.Ativo];
      },
      error => {
        this.alertService.hideProgressBar();
        this.alertService.error(error);
      }
    )
  }

  gerarNovaSenha(){
    this.novaSenha = '';
    this.modalSenhaVisibility = true;
  }

  salvarNovaSenha(){
    this.rowData.senha = this.novaSenha;
    this.alertService.showProgressBar();
    this.usuarioService.alterarSenha(this.rowData).subscribe(
      success => {
        this.alertService.hideProgressBar();
        this.alertService.success(success.messages[0]);
        this.modalSenhaVisibility = false;
      },
      error => {
        this.alertService.hideProgressBar();
        this.alertService.error(error);
      }
    )
  }

  getUf(base,uf):any{
    if(base==undefined) return {codigo:'',nome:''};
    let data = base.filhos.find(x=>x.codigo==uf);
    if(data !=undefined){
      this.uf= data;
    }
    base.filhos.forEach(x=>{
      return this.getUf(x, uf);
    });
  }
}
