import {Component, OnInit} from '@angular/core';

import {TableService} from "../_services/table.service";
import {ViewCliente} from "../_models/viewCliente";
import {FiltroClienteDto} from "../_models/filtros/filtro-cliente-dto";
import {ColumnType} from "../_models/enums/column-type";
import {SituacaoClienteMapping} from "../_models/enums/situacaoCliente";
import {Router} from "@angular/router";
import {Cliente} from "../_models/cliente";
import {ClienteService} from "../_services/ClienteService";
import {AccountService, AlertService, PermissionService} from "../_services";
import {BehaviorSubject} from "rxjs";
import {EuropaMask} from "../_europa/europa-mask";
import {TipoCliente} from "../_models/enums/tipoCliente";

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css'],
  providers: [
    TableService
  ]
})
export class ClientesComponent implements OnInit {
  constructor(
    private router:Router,
    public table: TableService<ViewCliente>,
    private clienteService:ClienteService,
    private alertService:AlertService,
    public permissionService: PermissionService,
    public accountService:AccountService) { }
  cliente:Cliente;
  nomeCliente;
  cpfCnpj;
  responsavel;
  telefone;
  situacao = []
  situacoes = SituacaoClienteMapping;
  acoesTabela;
  nome;
  private usuarioEdit = new BehaviorSubject<any>({});
  usuarioEDIT = this.usuarioEdit.asObservable();
  uf;

  ngOnInit(): void {
    this.getUf(this.accountService.userValue.menu,'CAD01');
    this.table.cols = [
      {field:'nome',header:'Nome',width:'10%'},
      {field:'cpf',header:'CPF/CNPJ',width:'10%'},
      {field: 'tipoCliente', header: 'Tipo Cliente', width: '10%', type: ColumnType.Enum, format: 'TipoCliente'},
      {field:'nomeResponsavel',header:'Responsável',width:'10%'},
      {field:'emailResponsavel',header:'E-mail',width:'10%'},
      {field:'telefoneResponsavel',header:'Telefone',width:'10%'},
      {field: 'situacao', header: 'Situação', width: '10%', type: ColumnType.Enum, format: 'SituacaoCliente'},
    ];
    this.table.setColActionsWidth('10%').withUrlAction2('cliente/listarClientes',this.filtro());
    this.usuarioEDIT.subscribe((value) => {
      this.cliente = value;
    });
    this.generateAcoesTabela();
  }
  generateAcoesTabela() {
    this.acoesTabela = [
      {label: 'Visualizar', icon: 'pi pi-eye',field:'uuid', command: (event) => this.visualizarCliente(),visible:this.permissionService.hasPermission('CAD03','Visualizar')},
      {label: 'Ativar', icon: 'pi pi-minus-circle', command: (event) => this.ativarCliente(),visible:this.permissionService.hasPermission('CAD03','Ativar')},
      {label: 'Suspender', icon: 'pi pi-times-circle', command: (event) => this.suspenderCliente(),visible:this.permissionService.hasPermission('CAD03','Suspender')},
      {label: 'Cancelar', icon: 'pi pi-lock', command: () => this.cancelarCliente(),visible:this.permissionService.hasPermission('CAD03','Cancelar')}
    ];
  }
  filtrar(){
    this.table.reloadData2(this.filtro());
  }
  filtro(){
    var filtro = new FiltroClienteDto();
    filtro.NomeCliente=this.nomeCliente;
    filtro.CpfCnpj=this.cpfCnpj;
    filtro.Responsavel=this.responsavel;
    filtro.Telefone=this.telefone;
    filtro.Situacao=this.situacao;
    return filtro;
  }
  limparFiltro(){
    this.nomeCliente='';
    this.cpfCnpj='';
    this.responsavel='';
    this.telefone='';
    this.situacao=[];
  }
  incluirCliente(){
    this.router.navigate(['/clientes/incluir'])
  }
  renderCpfCnpj(row){
    if(row.tipoCliente==TipoCliente.Fisica) return  EuropaMask.cpfMask(row.cpf);
    return EuropaMask.cnpjMask(row.cnpj);
  }
  visualizarCliente(){
    this.router.navigate(['/clientes/incluir'],{queryParams:{uuid:this.cliente.id}});
  }
  ativarCliente(){
    this.clienteService.AtivarCliente(this.cliente.id).subscribe(
      res=>{
        if(res.success) {
          this.alertService.success(res.messages.join('<br/>'));
          this.filtrar();
        }else{
          this.alertService.error(res.messages.join('<br/>'))
        }
      }
    );
  }
  suspenderCliente(){
    this.clienteService.SuspenderCliente(this.cliente.id).subscribe(
      res=>{
        if(res.success) {
          this.alertService.success(res.messages.join('<br/>'));
          this.filtrar();
        }else{
          this.alertService.error(res.messages.join('<br/>'))
        }
      }
    );
  }
  cancelarCliente(){
      this.clienteService.CancelarCliente(this.cliente.id).subscribe(
        res=>{
          if(res.success) {
            this.alertService.success(res.messages.join('<br/>'));
            this.filtrar();
          }else{
            this.alertService.error(res.messages.join('<br/>'))
          }
        }
      );
  }
  opcaoSelecionada(cliente:any){
    this.cliente=cliente;
  }
  telefoneMask(cliente:Cliente){
    return EuropaMask.telefoneMask(cliente.telefoneResponsavel);
  }
  getUf(base,uf):any{
    if(base==undefined) return {codigo:'',nome:''};
    let data = base.filhos.find(x=>x.codigo==uf);
    if(data !=undefined){
      this.uf= data;
    }
    base.filhos.forEach(x=>{
      return this.getUf(x, uf);
    });
  }
}
