import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {PaginatorModule} from 'primeng/paginator';
import {AccountService, AlertService, PermissionService} from 'src/app/_services';
import {ConfirmationService} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {Parametro} from 'src/app/_models/parametro';
import {ParametroService} from 'src/app/_services/parametros.service';
import {TableService} from '../../_services/table.service';
import {ParametroRequest} from 'src/app/_models/parametroRequest';

@Component({
  selector: 'app-parametro',
  templateUrl: './parametro.component.html',
  styleUrls: ['./parametro.component.css'],
  providers: [
    TableService,
    PaginatorModule,
    ConfirmDialogModule,
    ConfirmationService
  ]
})
export class ParametroComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    public permissionService: PermissionService,
    public service: ParametroService,
    public table: TableService<Parametro>,
    private alertService: AlertService,
    private confirmationService: ConfirmationService,
    public accountService:AccountService
  ) {
  }

  request = new ParametroRequest();
  formParametro: UntypedFormGroup;
  formInclusao: UntypedFormGroup;
  rows = 10;
  first = 1;
  sistemas = [];
  edit = false;
  modalInclusao: boolean = false;
  uf;
  sistemaEdit;
  ngOnInit(): void {
    this.getUf(this.accountService.userValue.menu,'SEG03');
    this.createFormParametro(new ParametroRequest());
    this.listarSistemas();

    this.createFormInclusao(new ParametroRequest());

    this.createTable();
    this.filtrar();
  }
  createFormParametro(filtro) {
    this.formParametro = this.formBuilder.group({
      codigoSistema: [filtro.codigoSistema],
      nome:[filtro.nome]
    })
  }
  createFormInclusao(filtro) {
    this.formInclusao = this.formBuilder.group({
      sistema: [filtro.sistema],
      chave: [filtro.chave],
      valor: [filtro.valor],
      descricao: [filtro.descricao]
    })
  }
  confirmarInclusao(form?) {
    var entity = new Parametro();
    entity.codigoSistema = form.sistema?.codigo;
    entity.chave = form.chave;
    entity.valor = form.valor;
    entity.descricao = form.descricao;
    this.service.incluirParametro(entity).subscribe(
      response => {
        if(response.success){
          this.alertService.success(response.messages.join('<br/>'));
          this.modalInclusao = false;
          this.formInclusao.reset();
          this.filtrar();
        }else{
          this.alertService.error(response.messages.join('<br/>'));
        }
      },
      error => {
        this.alertService.error(error);
        this.modalInclusao = false;
      }
    );
  }
  cancelarInclusao() {
    this.formInclusao.reset();
    this.modalInclusao = false;
  }
  onRowEditSave(entity: Parametro) {
    if(this.sistemaEdit!=undefined) {
      entity.codigoSistema = this.sistemaEdit.codigo;
      this.service.alterarParametro(entity).subscribe(
        response => {
          if(response.success){
            this.alertService.success(response.messages.join('<br/>'));
            this.modalInclusao = false;
            this.formInclusao.reset();
            this.filtrar();
          }else{
            this.alertService.error(response.messages.join('<br/>'));
          }
        }, error => this.alertService.error(error)
      );
    }
    this.sistemaEdit=undefined;
  }
  showDialog() {
    this.modalInclusao = true;
  }

  //HubHawk
  createTable(){
    this.table.cols = [
      {field: 'nomeSistema', header: 'Sistema', width: '22,5%'},
      {field: 'chave', header: 'Chave', width: '22,5%'},
      {field: 'valor', header: 'Valor', width: '22,5%'},
      {field: 'descricao', header: 'Descrição', width: '22,5%'},
    ];
    this.table.setColActionsWidth('10%').withUrlAction2('parametro/listar',this.filtro());
  }
  filtro(){
    return {
      codigoSistema:this.formParametro.value.codigoSistema,
      nome:this.formParametro.value.nome
    };
  }
  filtrar(){
    this.table.reloadData2(this.filtro());
  }
  limpar() {
    this.formParametro.reset();
    this.filtrar();
  }
  listarSistemas() {
    this.service.listarSistema().subscribe(
      response => {
        this.sistemas = response.data;
        // this.formParametro.value.codigoSistema = "HUBHAWK";
      },
      error => {
        this.alertService.error(error)
      }
    );
  }
  confirmarExclusao(entity) {
    this.confirmationService.confirm({
      message: `Deseja realmente excluir o pararâmetro [${entity.chave}]? `,
      accept: () => {
        this.service.removerParametro(entity.id).subscribe(
          response => {
            if (response.code == 200) {
              this.alertService.success(response.messages);
              this.filtrar();
            }
          }, error => this.alertService.error(error)
        )
      }
    });
  }
  getUf(base,uf):any{
    if(base==undefined) return {codigo:'',nome:''};
    let data = base.filhos.find(x=>x.codigo==uf);
    if(data !=undefined){
      this.uf= data;
    }
    base.filhos.forEach(x=>{
      return this.getUf(x, uf);
    });
  }
}
