import {Injectable} from '@angular/core';
import {AccountService} from './account.service';

@Injectable({providedIn: 'root'})
export class PermissionService {
  constructor(
    private accountService: AccountService
  ) {
  }

  hasPermission(uf: string, funcionalidade: string) {
    const permissoesUf = this.accountService.userValue?.permissoes[uf.toUpperCase()];
    const permissao = permissoesUf?.includes(funcionalidade.toUpperCase());
    return permissao;
  }

  hasPermissionUf(uf: string) {
    const permissoesUf = this.accountService.userValue?.permissoes[uf.toUpperCase()];
    return permissoesUf;
  }
}
