import {HttpClient} from "@angular/common/http";
import {BaseResponse, DataSourceResponse} from "../_models";
import {environment} from 'src/environments/environment';
import {Injectable} from "@angular/core";
import {Cliente} from "../_models/cliente";
import {AlertService} from "./alert.service";
import { DataSourceRequest } from "../_models/data-source-request";

@Injectable({providedIn: 'root'})
export class ClienteService {

  constructor(private http: HttpClient,private alertService:AlertService) {
  }

  listarClientes(request){
    return this.http.post<any>(`${environment.apiUrl}/cliente/listarClientes`, request);
  }
  BuscarCliente(uuid:string){
    let result = this.http.get<Cliente>(environment.apiUrl+'/cliente/'+uuid);
    return result;
  }
  CadastrarCliente(cliente:Cliente){
    var result = this.http.post<BaseResponse<Cliente>>(environment.apiUrl+'/cliente/cadastrar',cliente);
    return result;
  }

  AlterarCliente(cliente:Cliente){
    var result = this.http.put<BaseResponse<Cliente>>(environment.apiUrl+'/cliente/alterar',cliente);
    return result;
  }

  AtivarCliente(uuid:string){
    var result = this.http.put<BaseResponse<Cliente>>(environment.apiUrl+'/cliente/ativar/'+uuid,{});
    return result;
  }

  SuspenderCliente(uuid:string){
    var result = this.http.put<BaseResponse<Cliente>>(environment.apiUrl+'/cliente/suspender/'+uuid,{});
    return result;
  }

  CancelarCliente(uuid:string){
    var result = this.http.put<BaseResponse<Cliente>>(environment.apiUrl+'/cliente/cancelar/'+uuid,{});
    return result;
  }

}
