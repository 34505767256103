<div class="container-fluid" [style.padding-right]="(table.total == undefined) ? '64px' : '57px'">
  <div class="row justify-content-between no-gutters">
    <h2>{{nomeUf}}</h2>
    <p-dropdown [options]="sistemas" [autoDisplayFirst]="false" [(ngModel)]="sistemaSelecionado"
      (onChange)="selecionarSistema($event)" optionLabel="nome" placeholder="Selecione um sistema"
      *ngIf="permissionService.hasPermission('SEG02','Listar')"></p-dropdown>
  </div>
  <div class="row">
    <br>
    <div class="col-3 align-self-start" *ngIf="sistemaSelecionado != undefined">
      <form [formGroup]="filtroPerfis">
        <br>
        <div class="p-inputgroup" [style]="{'width':'16.0rem'}">
          <span class="p-float-label">
            <button type="submit" pButton pRipple icon="pi pi-search" class="inputGroupButtonFix"
              (click)="carregarPerfis(filtroPerfis.value.buscarPerfil)"></button>
            <input id="float-input" pInputText type="text" formControlName="buscarPerfil">
            <button type="button" pButton pRipple icon="pi pi-times" (click)="limparPerfis()" class="inputGroupButtonFix"></button>
            <label for="float-input">Busque por perfil</label>                     
          </span>
        </div>
        <p-listbox [options]="perfis" formControlName="perfilSelecionado" (onChange)="selecionarPerfil($event)"
          optionLabel="nomePerfil" [style]="{'width':'16.0rem'}"
          *ngIf="permissionService.hasPermission('SEG02','Listar')"></p-listbox>
      </form>
    </div>
    <div class="col-9">
      <br>
      <form [formGroup]="filtroPermissoes" class="row no no-gutters" *ngIf="table.records != undefined">
        <div class="col-5">
          <span class="p-float-label">
            <input type="search" pInputText class="form-control" formControlName="unidadeFuncional">
            <label for="float-input">Unidade funcional ou Funcionalidade</label>
          </span> 
        </div>
        <div class="col-4 alignAndJustifyEnd offset-3">
            <button class="p-button-primary" icon="pi pi-search" label="Filtrar" pButton
                    (click)="buscar(filtroPermissoes.value.unidadeFuncional)" type="button"></button>
            <button class="p-button-secondary" icon="pi pi-times" label="Limpar" pButton
                    (click)="limparPermissoes()" type="button" style="margin-left: 2%;"></button>
        </div>
      </form>
      <br>
      <p-table *ngIf="table.records != undefined && permissionService.hasPermission(this.uf.codigo,'Listar')"
                responsiveLayout="scroll" 
                [(first)]="this.table.request.start" 
                class="col-12" styleClass="p-datatable-gridlines p-datatable-striped"
                [lazy]="true"
                [columns]="table.cols" 
                [value]="table.records" 
                (onLazyLoad)="this.table.init($event)" 
                [loading]="table.loading"
                [showCurrentPageReport]="true"
                [paginator]="true"
                [rows]=table.rowPerPage[0]
                [rowsPerPageOptions]=table.rowPerPage
                [totalRecords]="table.total"
                sortField="codigoUnidadeFuncional" 
                [sortOrder]="1">
        <ng-template pTemplate="caption">
          <div class="table-header row justify-content-between">
            <div *ngIf="perfilSelecionado != undefined">
              Perfil: {{perfilSelecionado.nomePerfil}}
            </div>
            <div *ngIf="sistemaSelecionado != undefined">
              Sistema: {{sistemaSelecionado.nome}}
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" [ngStyle]="{'width': col.width}">
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="output">
                  <div class="text-center">{{rowData.codigoUnidadeFuncional}}</div>
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="output">
                  <div class="text-center">{{rowData.nomeUnidadeFuncional}}</div>
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="output">
                  <div class="text-center">{{rowData.nomeFuncionalidade}}</div>
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <div class="permissao text-center">
                <p-checkbox [(ngModel)]="rowData.numeroPermissao" (onChange)="mudarPermissao(rowData)" [binary]="true"
                  inputId="binary" *ngIf="permissionService.hasPermission(this.uf.codigo,'Incluir')"></p-checkbox>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td class="rowEmpty" [colSpan]="this.table.cols.length+1">
              <p *ngIf="table.records==undefined">Clique em filtrar para realizar uma busca</p>
              <p *ngIf="table.records!=undefined && table.records.length==0">Nenhum registro foi encontrado</p>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>