import { Component, OnInit } from '@angular/core';
import {AccountService, AlertService, PermissionService} from 'src/app/_services';
import { ParametroService } from 'src/app/_services/parametros.service';
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {ViewParametroSistema} from "../../_models/viewParametroSistema";

@Component({
  selector: 'app-parametro-sistema',
  templateUrl: './parametro-sistema.component.html',
  styleUrls: ['./parametro-sistema.component.css']
})
export class ParametroSistemaComponent implements OnInit {
  parametroSistema=new ViewParametroSistema();
  sistemas;
  sistemaSelecionado;
  servidorAd;
  dominioAd;
  grupoAd;
  idParametroSistema;
  uf;
  formParametroSistema;

  constructor(
    private alertService: AlertService,
    public permissionService: PermissionService,
    public service: ParametroService,
    public accountService:AccountService,
    public fb:UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.getUf(this.accountService.userValue.menu,'SEG04');
    this.createForm(new ViewParametroSistema());
    this.carregarSistemas();
    this.carregarParametroSistema();
  }
  createForm(parametro:ViewParametroSistema){
    this.formParametroSistema=this.fb.group({
      idParametroSistema:new UntypedFormControl({value: parametro.id}),
      codigoSistema:new UntypedFormControl({value: parametro.codigoSistema, disabled: !this.permissionService.hasPermission(this.uf.codigo,'Atualizar')}),
      perfilInicial:new UntypedFormControl({value: parametro.nomePerfilInicial, disabled: !this.permissionService.hasPermission(this.uf.codigo,'Atualizar')}),
      servidorAd:new UntypedFormControl({value: parametro.servidorAd, disabled: !this.permissionService.hasPermission(this.uf.codigo,'Atualizar')}),
      dominioAd:new UntypedFormControl({value: parametro.dominioAd, disabled: !this.permissionService.hasPermission(this.uf.codigo,'Atualizar')}),
      grupoAd:new UntypedFormControl({value: parametro.grupoAd, disabled: !this.permissionService.hasPermission(this.uf.codigo,'Atualizar')}),
    });
  }
  carregarParametroSistema(){
    this.service.selecionarParametroSistema(this.accountService.userValue.codigoSistema).subscribe(
      response => {
        this.parametroSistema = response.data.records[0];
        if(response.data.records[0]!=undefined) {
          this.createForm(this.parametroSistema);
        }
    });
  }
  carregarSistemas(){
    this.service.listarSistemas().subscribe(
      data => {
        this.sistemas = data.data;
      },
      error => this.alertService.error(error)
    )
  }
  selecionarSistema(){
    this.service.selecionarParametroSistema(this.sistemaSelecionado).subscribe(
      response => {
        this.parametroSistema = response.data.records[0];
        if(response.data.records[0]==undefined){
          let novo = new ViewParametroSistema();
          novo.codigoSistema=this.sistemaSelecionado;
          this.createForm(novo);
        }else{
          this.createForm(this.parametroSistema);
        }
      },
      error => this.alertService.error(error)
    )
  }
  salvarAlteracoes(){
    this.formParametroSistema.value.id = this.formParametroSistema.value.idParametroSistema.value;
    this.service.alterarParametroSistema(this.formParametroSistema.value).subscribe(
      res => {
        if(res.success) {
          this.alertService.success(res.messages.join('<br/>'));
        }else{
          this.alertService.error(res.messages.join('<br/>'));
        }
      },
      error => this.alertService.error(error)
    );
  }
  getUf(base,uf):any{
    if(base==undefined) return {codigo:'',nome:''};
    let data = base.filhos.find(x=>x.codigo==uf);
    if(data !=undefined){
      this.uf= data;
    }
    base.filhos.forEach(x=>{
      return this.getUf(x, uf);
    });
  }
}
