<div class="main">
  <div class="page-title on-card-hover">Escolha a plataforma que deseja acessar:</div>
  <div class="card-container on-card-hover">
    <a class="admin-card mx-auto" (mouseenter)="cardHover(cards.atalho)" (mouseleave)="cardLeave()" routerLink="/administracao/atalho">
      <span class="atalho" style="width:48px; height:48px;" ></span>
      <span class="title">Atalhos</span>
    </a>
    <a class="admin-card mx-auto" (mouseenter)="cardHover(cards.perfis)" (mouseleave)="cardLeave()" routerLink="/administracao/profile">
      <span class="perfis" style="width:48px; height:48px;" ></span>
      <span class="title">Perfis</span>
    </a>
    <a class="admin-card mx-auto" (mouseenter)="cardHover(cards.notificacao)" (mouseleave)="cardLeave()" routerLink="/administracao/notificacao">
      <span class="notificacao" style="width:48px; height:48px;" ></span>
      <span class="title">Notificações</span>
    </a>
  </div>
  <div class="card-container2 on-card-hover">
    <a class="admin-card mx-auto" (mouseenter)="cardHover(cards.usuarios)" (mouseleave)="cardLeave()" routerLink="/administracao/usuarios">
      <span class="usuarios" style="width:48px; height:48px;" ></span>
      <span class="title">Usuários</span>
    </a>
    <a class="admin-card mx-auto" (mouseenter)="cardHover(cards.seguranca)" (mouseleave)="cardLeave()" routerLink="/administracao/seguranca">
      <span class="seguranca" style="width:48px; height:48px;" ></span>
      <span class="title">Segurança</span>
    </a>
    <a class="admin-card mx-auto" (mouseenter)="cardHover(cards.perfilsistemagrupoad)" (mouseleave)="cardLeave()" routerLink="/administracao/perfilsistemagrupoad">
      <span class="perfilsistemagrupoad" style="width:48px; height:48px;" ></span>
      <span class="title">Associação Perfil x Grupo AD</span>
    </a>
  </div>
  <div class="card-container3 on-card-hover">
    <a class="admin-card mx-auto" (mouseenter)="cardHover(cards.permissoes)" (mouseleave)="cardLeave()" routerLink="/administracao/permissoes">
      <span class="permissoes" style="width:48px; height:48px;" ></span>
      <span class="title">Permissões</span>
    </a>
    <a class="admin-card mx-auto" (mouseenter)="cardHover(cards.parametros)" (mouseleave)="cardLeave()" routerLink="/administracao/parametros">
      <span class="parametros" style="width:48px; height:48px;" ></span>
      <span class="title">Parâmetros</span>
    </a>
    <a class="admin-card mx-auto" (mouseenter)="cardHover(cards.parametrosSistema)" (mouseleave)="cardLeave()" routerLink="/administracao/parametroSistema">
      <span class="parametroSistema" style="width:48px; height:48px;" ></span>
      <span class="title">Parametros de Sistema</span>
    </a>
  </div>
  <div class="card-description-container on-card-hover">
    <div id="card-description" class="card-description on-card-hover"></div>
  </div>
  <div class="flex-grow-2 bottom-space"></div>
</div>
