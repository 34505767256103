import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {AccountService, PermissionService} from '../../_services';
import { TableService } from '../../_services/table.service';
import { PermissoesService } from '../../_services/permissoes.service';
import { FiltroPermissoes } from '../../_models/filtros/filtro-permissoes';
import { PerfilPermissao } from '../../_models/perfilPermissao';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-permissoes',
  templateUrl: './permissoes.component.html',
  styleUrls: ['./permissoes.component.css'],
  providers: [
    TableService
  ]
})
export class PermissoesComponent implements OnInit {

  //variaveis do property binding dos dropdowns
  perfilSelecionado;
  sistemaSelecionado:any;

  //lista de opções dos dropdowns
  perfis;
  sistemas;

  perfilVigente;

  //Formularios para encapsular os valores dos filtros
  filtroPermissoes: UntypedFormGroup;
  filtroPerfis:UntypedFormGroup;

  //Nome da tela
  uf:{ codigo, nome};
  nomeUf;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public permissionService: PermissionService,
    private service:PermissoesService,
    public table:TableService<PerfilPermissao>,
    public accountService:AccountService) {
    this.getUf(this.accountService.userValue.menu,'SEG07');
  }

  ngOnInit(): void {
    this.createForm(new FiltroPermissoes());
    this.carregarSistemas();
    this.table.cols = [
      {field : 'codigoUnidadeFuncional', header:'Código UF', width: '17%'},
      {field : 'nomeUnidadeFuncional', header:'Unidade Funcional', width: '24%'},
      {field : 'nomeFuncionalidade', header:'Nome Funcionalidade', width: '29%'},
      {field : 'numeroPermissao', header:'Habilitar permissão', width: '18%'},
      // {field : 'logar', header:'logar?', width: '12%'}
    ];
  }

  carregarUnidadesFuncionais(ufOuFuncionalidade?){
    this.service.listarUnidadesFuncionais(this.perfilSelecionado.idPerfil, this.sistemaSelecionado.codigo, ufOuFuncionalidade).subscribe(
      response => {        
        this.adjustTableUrlBase(ufOuFuncionalidade);
        this.table.records = response.data.records;
        this.table.loading = false;
        this.table.total = response.data.total;
      }
    );
  }

  buscar(ufOuFuncionalidade?){
    this.adjustTableUrlBase(ufOuFuncionalidade);
    this.table.reloadData();
  }

  limparPerfis(){
    this.filtroPerfis.reset();
    this.carregarPerfis("");
  }
  
  limparPermissoes(){
    this.filtroPermissoes.reset();
    this.adjustTableUrlBase();
    this.table.reloadData();
  }

  selecionarSistema(sistema?){
    this.table.records = undefined;
    this.table.total = undefined;
    this.perfilSelecionado = undefined;
    this.sistemaSelecionado = sistema.value;
    this.limparPerfis();
    this.carregarPerfis("");
  }

  selecionarPerfil(event){
    this.perfilSelecionado = event.value;
    this.carregarUnidadesFuncionais()
  }

  carregarSistemas(){
    this.service.listarSistemas().subscribe(
      data => {
        this.sistemas = data.data;
      }
    )
  }

  carregarPerfis(queryPerfil){
    queryPerfil == null ? queryPerfil = '': '';
    this.service.listarPerfis(queryPerfil).subscribe(
      data => {
        this.perfis = data.data.records;
      }
    );
  }

  mudarPermissao(funcionalide){
    !funcionalide.numeroPermissao ?
    this.service.removerPermissao(funcionalide.idPerfil, funcionalide.idFuncionalidade).subscribe() :
    this.service.concederPermissao(funcionalide.idPerfil, funcionalide.idFuncionalidade).subscribe();
  }

  createForm(filtro?){
    this.filtroPermissoes = this.formBuilder.group({
      unidadeFuncional: [filtro.unidadeFuncional]
    });
    this.filtroPerfis = this.formBuilder.group({
      buscarPerfil: [filtro.buscarPerfil],
      perfilSelecionado: [filtro.perfilSelecionado]
    });
  }

  refresh() {
    this.table.request.cleanFilter();
    this.table.reloadData();
  }

  getUf(base,uf){
    if(base == undefined) return { codigo: '', nome: ''};

    let data = base.filhos.find(x => x.codigo == uf);

    if(data != undefined){
      this.uf = data;
      this.nomeUf = data.nome;
    }

    base.filhos.forEach(x => {
      return this.getUf(x, uf);
    });
  }

  adjustTableUrlBase(ufOuFuncionalidade?){
    ufOuFuncionalidade == undefined ? ufOuFuncionalidade = "" : ufOuFuncionalidade = ufOuFuncionalidade
    this.table._url = `${environment.apiUrl}/permissao?idPerfil=${this.perfilSelecionado.idPerfil}&query=${ufOuFuncionalidade}&cdSistema=${this.sistemaSelecionado.codigo}`
  }
}
