<header *ngIf="user" id="main-header">
  <div class="d-flex align-items-center">
    <a routerLink="/"><img src="../assets/svg/logo.svg"> <img src="../assets/img/text-teia.png" class="ml-3"
                                                              *ngIf="isOnHomePage()"></a>
    <div class="d-flex align-items-center ml-5" *ngIf="showSearchBar()">
      <div class="search-container">
        <input #searchInput type="search" id="search-input" class="grey-input" placeholder="Pesquise por nome"
               (keyup.enter)="buscar()">
        <span id="search-button" class="ml-2 mr-4 btn-search" title="Buscar" (click)="buscar()">
          <img class="search-grey" src="../../../assets/svg/icon-search.svg"/>
          <img class="search-red oculto" src="../../../assets/svg/icon-search-red.svg"/>
        </span>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <div>{{date.getHours() | greetings}} <span class="font-weight-semi-bold">{{ user.firstName }}</span></div>
    <div class="c-pointer mx-2"><i class="pi pi-cog" (click)="openSystemModal()"
                                   style="color: #e20721; font-size: 24px;"></i></div>
    <div class="c-pointer mx-2"><i (click)="onClickTooltip()" tooltipEvent="focus" [pTooltip]="tooltipText" #tooltipId
                                   title="Informações"
                                   tooltipPosition="bottom" class="pi pi-info-circle"
                                   style="color: #e20721; font-size: 24px;"></i></div>
    <div class="c-pointer mx-2" title="Notificações"
         *ngIf="permissionService.hasPermission('NTF01', 'Visualizar'); else notificationsDisabled">
      <img id="icon-bell-none" src="../assets/svg/icon-bell-none.svg" (click)="showSidebar()"/>
      <img id="icon-bell-red" src="../assets/svg/icon-bell-red.svg" class="d-none" (click)="showSidebar()"/>
    </div>
    <ng-template #notificationsDisabled>
      <img class="c-pointer mx-2" title="Notificações" src="../assets/svg/icon-bell-none.svg"/>
    </ng-template>
    <div>
      <img (click)="menuId.toggle($event)" src="../assets/svg/icon-menu.svg" class="menu-opener" title="Menu" alt="Menu"/>


      <p-slideMenu [viewportHeight]="menuHeight" [menuWidth]="280" [style]="{ width: '280px' }" #menuId [model]="items"
                   [popup]="true"></p-slideMenu>

    </div>
  </div>
</header>

<div id="modal-logout" class="modal modal-pai" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Aguarde...</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="modalService.hide('modal-logout')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated w-100" role="progressbar"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Definição do Sistema" modal="true" [(visible)]="modalSistema">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p>Escolha o Sistema desejado: </p>
        <p-dropdown class="dropDownSistemas" placeholder="Escolha o sistema..." (onChange)="listarSistemas()"
                    [options]="sistemas" [autoDisplayFirst]="false" [(ngModel)]="sistemaSelecionado"
                    optionLabel="nome"></p-dropdown>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button type="submit" class="button" (click)="confirmarSistema()"
            style="background-color:#1bae3dfe; color:white; float: right;">Confirmar
    </button>
  </ng-template>
</p-dialog>
