<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/">Portal</a></li>
          <li class="breadcrumb-item"><a routerLink="/">Dashboard</a></li>
          <li class="breadcrumb-item"><a routerLink="/clientes">{{uf.nome}}</a></li>
          <li class="breadcrumb-item active" aria-current="page" *ngIf="this.uuid==undefined && permissionService.hasPermission(uf.codigo,'Incluir')">Incluir</li>
          <li class="breadcrumb-item active" aria-current="page" *ngIf="this.uuid!=undefined && permissionService.hasPermission(uf.codigo,'Atualizar')">Editar</li>
        </ol>
      </nav>
    </div>
  </div>
  <form [formGroup]="formCliente">
    <div class="row">
      <div class="col-2">
        <span class="p-float-label">
            <input id="float-input" pInputText formControlName="codigo" type="text" class="form-control" disabled>
            <label for="float-input">Código</label>
        </span>
      </div>
      <div class="col-4">
        <span class="p-float-label">
            <input id="float-input" pInputText formControlName="nome" type="text" class="form-control">
            <label for="float-input">Nome do Cliente</label>
        </span>
      </div>
      <div class="col-2">
        <span class="p-float-label">
          <p-inputMask formControlName="cpf" mask="999.999.999-99"></p-inputMask>
          <label for="float-input">CPF</label>
        </span>
      </div>
      <div class="col-2">
        <span class="p-float-label">
          <p-inputMask formControlName="cnpj" mask="99.999.999/9999-99"></p-inputMask>
          <label for="float-input">CNPJ</label>
        </span>
      </div>
      <div class="col-1">
        <span class="p-float-label">
          <p-dropdown [options]="tiposCliente" formControlName="tipoCliente"
                      optionLabel="nome" optionValue="value" [autoDisplayFirst]="false"></p-dropdown>
          <label for="float-input">Tipo</label>
        </span>
      </div>
      <div class="col-1">
        <span class="p-float-label">
          <p-dropdown [options]="situacoes" formControlName="situacao"
                      optionLabel="nome" [autoDisplayFirst]="false" optionValue="value"></p-dropdown>
          <label for="float-input">Situação</label>
        </span>
      </div>
    </div>
    <div class="row" style="margin-top: 2%;">
      <div class="col-3">
        <span class="p-float-label">
                    <input id="float-input" pInputText formControlName="nomeResponsavel" type="text" class="form-control">
                    <label for="float-input">Nome do Responsável</label>
                </span>
      </div>
      <div class="col-3">
        <span class="p-float-label">
                    <input id="float-input" pInputText formControlName="emailResponsavel" type="text" class="form-control">
                    <label for="float-input" >E-mail do Responsável</label>
                </span>
      </div>
      <div class="col-3">
        <span class="p-float-label">
          <p-inputMask formControlName="telefoneResponsavel" mask="(99) 99999-9999"></p-inputMask>
          <label for="float-input">Telefone do Responsável</label>
        </span>
      </div>
      <div class="col-3" style="display: flex;justify-content: flex-end;">
        <button pButton pRipple icon="pi pi-save" type="button"
          (click)="salvarCliente()" label="Salvar" class="p-button-info"
                *ngIf="permissionService.hasPermission(uf.codigo,'Incluir') ||
                permissionService.hasPermission(uf.codigo,'Atualizar')">
        </button>
        <button pButton pRipple icon="pi pi-block" type="button"
                (click)="cancelar()" label="Cancelar" class="p-button-secondary"
                *ngIf="permissionService.hasPermission(uf.codigo,'Incluir') ||
                permissionService.hasPermission(uf.codigo,'Atualizar')">
        </button>
      </div>
    </div>
  </form>
</div>
