<p-sidebar [(visible)]="minhaNotificacaoService.showNotifications" position="right" [transitionOptions]="'400ms'" (onHide)="sidebarClosed()" id="sidenav" blockScroll="true">
  <div class="sidenav">
    <div class="sidebar-title">Notificações</div>
    <div id="notifications-container" class="notifications-container">
      <div *ngFor="let notificacao of notificacoes">
        <div class="notification-box">
          <div class="position-relative" *ngIf="permissionService.hasPermission(uf, 'MarcarLida')">
            <img class="notification-close" src="../../../assets/svg/icon-close.svg" title="Fechar" (click)="closeNotification($event, notificacao.id)">
          </div>
          <div class="notification-title">{{ notificacao.titulo }}</div>
          <div class="notification-text">{{ notificacao.texto }}</div>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>
