import { MenuItem } from "primeng/api";

export class LoginResponse {
    idUsuario: string;
    nomeUsuario: string;
    login: string;
    loja: string;
    token: string;
    codigoSistema:string;
    permissoes: Map<string, Array<string>>;
    menu: MenuItem;
}
