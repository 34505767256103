<div class="container-fluid">
    <div class="row justify-content-between no-gutters">
        <h2>{{uf.nome}}</h2>
        <button pButton pRipple icon="pi pi-plus" type="button"
                (click)="incluirUsuario()" label="Incluir" class="p-button-success">
        </button>
    </div>
    <div class="row" style="margin-top: 2%;">
        <div class="col-2">
            <span class="p-float-label">
                <input id="float-input" pInputText [(ngModel)]="nome" type="text" class="form-control"> 
                <label for="float-input">Nome</label>
            </span>  
        </div>
        <div class="col-3">
            <span class="p-float-label">
                <input id="float-input" pInputText [(ngModel)]="email" type="text" class="form-control"> 
                <label for="float-input">E-mail</label>
            </span>  
        </div>
        <div class="col-2">
            <span class="p-float-label">
                <p-inputMask id="float-input" [autoClear]="false" [(ngModel)]="telefone" mask="(99) 99999-9999"></p-inputMask>
                <label for="float-input">Telefone</label>
            </span>  
        </div>
        <div class="col-3">
            <span class="p-float-label">
                <p-multiSelect [options]="situacoes" [(ngModel)]="situacao" optionLabel="nome" optionValue="value" display="chip"></p-multiSelect>
                <label for="float-input">Situação</label>
            </span>  
        </div> 
        <div class="col-2 flexEnd">
            <button pButton pRipple icon="pi pi-search" type="button"
                    (click)="buscar()" label="Filtrar" class="p-button-primary">
            </button>
            <button pButton pRipple icon="pi pi-filter" type="button" style="margin-left: 3%;"
                    (click)="limparFiltro()" label="Limpar" class="p-button-secondary">
            </button>                
        </div>
    </div> 
    <br>
    <div class="row">
        <p-table responsiveLayout="scroll" 
                 [(first)]="this.table.request.start" 
                 class="col-12" styleClass="p-datatable-gridlines p-datatable-striped"
                 [lazy]="true"
                 [columns]="table.cols" 
                 [value]="table.records" 
                 (onLazyLoad)="this.table.init($event)" 
                 [loading]="table.loading"
                 [showCurrentPageReport]="true"
                 [paginator]="true"
                 [rows]=table.rowPerPage[0]
                 [rowsPerPageOptions]=table.rowPerPage
                 [totalRecords]="table.total"
                 sortField="nome" 
                 [sortOrder]="1">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="text-align:center" [ngStyle]="{'width': table.colActionsWidth}">Ações</th>
                    <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" [ngStyle]="{'width': col.width}">
                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td class="text-center col-actions">
                        <p-splitButton icon="pi pi-list" [model]="acoesTabela" (onDropdownClick)="getRowData(rowData)"></p-splitButton>
                    </td>
                    <td *ngFor="let col of columns" class="text-center {{col.class}}">
                        <p *ngIf="col.field == 'telefone'" [innerHtml]="telefoneMask(rowData)"></p>
                        <p *ngIf="col.field == 'cliente' && rowData[col.field] != null" [innerHtml]="rowData[col.field].nome"></p>
                        <p *ngIf="col.field != 'telefone' && col.field != 'cliente'" [innerHtml]="rowData[col.field] | columnRender : col.type:col.format"></p>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                  <td class="rowEmpty" [colSpan]="this.table.cols.length+1">
                    <p *ngIf="table.records==undefined">Clique em filtrar para realizar uma busca</p>
                    <p *ngIf="table.records!=undefined && table.records.length==0">Nenhum registro foi encontrado</p>
                  </td>
                </tr>
              </ng-template>
        </p-table>
    </div>
</div>

<!--MODAL PARA GERAR NOVA SENHA-->
<p-dialog header="Gerar Nova Senha" [(visible)]="modalSenhaVisibility" [modal]="true" [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
  <div class="container">
      <div class="form-group mt-2">
        <div class="content">
          <div class="row">
            <div class="col-9">
                <span class="p-float-label">
                    <p-password id="float-input" [toggleMask]="true" [(ngModel)]="novaSenha"></p-password>
                    <label for="float-input">Digite a nova senha</label>
                </span>  
            </div>
            <div class="col-3 flexEnd">
                <button (click)="salvarNovaSenha()" class="p-button-success" icon="pi pi-save" label="Salvar" pButton type="button"></button>
            </div>
          </div>
        </div>
      </div>
  </div>
</p-dialog>
