<div class="container-fluid">
  <div class="row justify-content-between">
    <div class="col-1"><h2>{{uf.nome}}</h2></div>
    <div class="col-1" style="display: flex;justify-content: flex-end;">
      <button style="margin-left: 3%; height: 35px;" pButton pRipple icon="pi pi-plus" type="button"
              (click)="incluirCliente()" label="Incluir" class="p-button-success"
              *ngIf="permissionService.hasPermission(uf.codigo,'Incluir')">
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <span class="p-float-label">
          <input id="float-input" pInputText [(ngModel)]="nomeCliente" type="text" class="form-control">
          <label for="float-input" style="font-size:16px;">Nome do Cliente</label>
      </span>
    </div>
    <div class="col-2">
      <span class="p-float-label">
          <input id="float-input" pInputText [(ngModel)]="cpfCnpj" type="text" class="form-control">
          <label for="float-input" style="font-size:16px;">CPF ou CNPJ</label>
      </span>
    </div>
    <div class="col-2">
      <span class="p-float-label">
          <input id="float-input" pInputText [(ngModel)]="responsavel" type="text" class="form-control">
          <label for="float-input" style="font-size:16px;">Nome ou E-mail do Responsável</label>
      </span>
    </div>
    <div class="col-2">
      <span class="p-float-label">
          <input id="float-input" pInputText [(ngModel)]="telefone" type="text" class="form-control">
          <label for="float-input" style="font-size:16px;">Telefone</label>
      </span>
    </div>
    <div class="col-2">
      <span class="p-float-label">
          <p-multiSelect [options]="situacoes" [(ngModel)]="situacao" optionLabel="nome" optionValue="value" display="chip"></p-multiSelect>
          <label for="float-input" style="font-size:16px;">Situação</label>
      </span>
    </div>
    <div class="col-2 fixButtons" style="display: flex;justify-content: flex-end;">
      <button style="height: 35px;" pButton pRipple icon="pi pi-search" type="button"
              (click)="filtrar()" label="Filtrar" class="p-button-primary">
      </button>
      <button style="margin-left: 3%; height: 35px;" pButton pRipple icon="pi pi-filter" type="button"
              (click)="limparFiltro()" label="Limpar" class="p-button-secondary">
      </button>
    </div>
  </div>
  <div class="row" *ngIf="permissionService.hasPermission(uf.codigo,'Visualizar')">
    <p-table class="col-12" styleClass="p-datatable-gridlines p-datatable-striped"
             [lazy]="true"
             [columns]="table.cols"
             [value]="table.records"
             (onLazyLoad)="this.table.init2($event)"
             sortField="nome" [sortOrder]="1"
             [loading]="table.loading"
             [showCurrentPageReport]="true"
             [paginator]="true"
             [rows]="10"
             [rowsPerPageOptions]=table.rowPerPage
             [totalRecords]="table.total">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th [ngStyle]="{'width': table.colActionsWidth}">Ações</th>
          <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" [ngStyle]="{'width': col.width}">
            <p-sortIcon field="{{col.field}}"></p-sortIcon>
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td class="text-center col-actions">
            <p-splitButton icon="pi pi-list" [model]="acoesTabela" (onDropdownClick)="opcaoSelecionada(rowData)"></p-splitButton>
          </td>
          <td *ngFor="let col of columns" class="{{col.class}}" class="text-center">
            <p *ngIf="col.field == 'telefoneResponsavel'" [innerHtml]="telefoneMask(rowData)"></p>
            <p *ngIf="col.field=='cpf'" [innerHTML]="renderCpfCnpj(rowData)"> </p>
            <p *ngIf="col.field!='cpf' && col.field != 'telefoneResponsavel'" [innerHtml]="rowData[col.field] | columnRender : col.type:col.format"> </p>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td class="rowEmpty" [colSpan]="this.table.cols.length+1">
            <p *ngIf="table.records==undefined">Clique em filtrar para realizar uma busca</p>
            <p *ngIf="table.records!=undefined && table.records.length==0">Nenhum registro foi encontrado</p>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
