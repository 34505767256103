import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { BaseResponse, DataSourceResponse} from '../_models';
import { DataSourceRequest } from '../_models/data-source-request';
import HttpClientUtils from '../_helpers/http-client-utils';
import { LazyLoadEvent } from 'primeng/api';
import { AlertService } from './alert.service';
import { FileDto } from '../_models/file-dto';
import { downloadFileDto } from '../_helpers';
import {SituacaoCliente, SituacaoClienteMapping} from "../_models/enums/situacaoCliente";
import {ColumnType} from "../_models/enums/column-type";
import {ViewPerfil} from "../_models/viewPerfil";
import {Perfil} from "../_models/perfil";

@Injectable()
export class TableService<T> {

  cols: any[];
  loading: boolean;
  total: number;
  request: DataSourceRequest;
  records: T[];
  colActionsWidth: string;
  _url: string;
  _autoInit: boolean;
  _filter: any;
  _body:any;
  callback: Function;

  //HUBHAWK
  clonedData: { [s: string]: T; } = {};
  data:any;
  editing:boolean=false;
  adding:boolean=false;
  isSubmitting:boolean=false;
  rowPerPage=[10,25,50,100];
  //HUBHAWK

  constructor(
    private http: HttpClient,
    private alertService: AlertService
  ) {
    this.loading = true;
    this.request = new DataSourceRequest();
    this.request.pageSize = 10;
    this._autoInit = true;
  }

  setDefaultOrder(column: string, order: string) {
    this.request.order = [{ column, value: order }]
    return this;
  }
  withUrlAction(url) {
    this._url = `${environment.apiUrl}/${url}`;
    return this;
  }

  changePage(rows, first) {
    this.request.pageSize = rows;
    this.request.start = first;
    this.loadData()
  }
  withPageSize(pageSize) {
    this.request.pageSize = pageSize;
    return this;
  }
  setAutoInit(autoInit) {
    this._autoInit = autoInit;
    this.loading = false;
    return this;
  }

  setColActionsWidth(width) {
    this.colActionsWidth = width;
    return this;
  }

  withFilter(filter: Object) {
    this._filter = filter;
    return this;
  }

  withCallback(callback: Function){
    this.callback = callback;
    return this;
  }

  _fromBaseResponse(res: BaseResponse<DataSourceResponse<T>>) {
    this.records = res.data.records;
    this.total = res.data.total;
    this.loading = false;
  }
  _fromLazyLoadEvent(event: LazyLoadEvent) {
    this.request.start = event.first;
    this.request.pageSize=event.rows;
    if (event.sortField && event.sortOrder) {
      const value = event.sortOrder == 1 ? "asc" : "desc";
      this.request.order = [{ column: event.sortField, value }]
    }
  }
  _getParams() {
    let filtro;
    if (this._filter) {
      filtro = this._filter;
      filtro.DataSourceRequest = this.request;
    } else {
      filtro = this.request;
    }

    const params = HttpClientUtils.toHttpParams(filtro);
    return params;
  }
  init(event: LazyLoadEvent) {
    this._fromLazyLoadEvent(event);
    if (this._autoInit) {
      this.loadData();
    }
  }

  loadData() {
    this.loading = true;
    const params = this._getParams();
    this.http.get<BaseResponse<DataSourceResponse<T>>>(this._url, { params }).subscribe(
      res => {
        this._fromBaseResponse(res);
        if (this.callback){
          this.callback(res);
        }
      },
      error => {
        this.loading = false;
        this.alertService.error(error);
      }
    );
  }
  reloadData() {
    this.request.start = 0;
    this.loadData();
  }
  exportar(action: string) {
    const params = this._getParams();
    return this.http.get<BaseResponse<FileDto>>(`${environment.apiUrl}/${action}`, { params }).subscribe(
      res => {
        const file = res.data;
        downloadFileDto(file);
      },
      error => {
        this.alertService.error(error);
      },
    );
  }

  //HUBHAWK
  init2(event: LazyLoadEvent) {
    this._fromLazyLoadEvent(event);
    if (this._autoInit) {
      this.loadData2();
    }
  }
  withUrlAction2(url,body) {
    this._url = `${environment.apiUrl}/${url}`;
    this._body=body;
    return this;
  }
  _fromDataSourceResponse(res: DataSourceResponse<T>) {
    this.records = res.records;
    this.total = res.total;
    this.loading = false;
  }
  loadData2() {
    this.editing=false;
    this.adding=false;
    this.loading = true;
    this._body.request=this.request;
    this.http.post<DataSourceResponse<T>>(this._url,this._body).subscribe(
      res => {
        this._fromDataSourceResponse(res);
        if (this.callback){
          this.callback(res);
        }
      },
      error => {
        this.loading = false;
        this.alertService.error(error);
      }
    );
  }
  reloadData2(filtro) {
    this._body=filtro;
    this.request.start = 0;
    this.loadData2();
  }
  onRowEditInit(rowData:T) {
    this.clonedData[rowData['id']] = {...rowData};
    this.editing=true;
    this.adding=false;
  }
  onSelectData(data:any){
    this.data=data;
  }
  onRowEditCancel(rowData: T, index: number) {
    this.records[index] = this.clonedData[rowData['id']];
    delete this.clonedData[rowData['id']];
    this.isSubmitting=false;
    this.editing=false;
    this.adding=false;
  }

  //HUBHAWK

}
