import {Component, Input, OnInit} from '@angular/core';
import { ModalService} from "../../_services";
import { ModalVisualizacao } from 'src/app/_models/components/modal-visualizacao';

@Component({
  selector: 'app-modal-visualizacao',
  templateUrl: './modal-visualizacao.component.html',
  styleUrls: [
    './modal-visualizacao.component.css',
    '../../administracao/administracao.css'
  ]
})
export class ModalVisualizacaoComponent implements OnInit {
  @Input() model: ModalVisualizacao;
  
  constructor(public modalService: ModalService){
  }

  ngOnInit(): void {
  }

  close() {
    this.modalService.visualizeVisible = false;
  }
}
