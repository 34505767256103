import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalExclusao } from '../../_models/components/modal-exclusao';
import { ModalService } from '../../_services';

@Component({
  selector: 'app-modal-exclusao',
  templateUrl: './modal-exclusao.component.html',
  styleUrls: ['./modal-exclusao.component.css']
})
export class ModalExclusaoComponent implements OnInit {
  @Input() model: ModalExclusao;
  @Output() deleteEvent = new EventEmitter<number>();

  constructor(public modalService: ModalService) { }

  ngOnInit(): void {
  }

  close() {
    this.modalService.excludeVisible = false;
  }

  exclude() {
    this.close();
    this.deleteEvent.emit(this.model.id);
  }
}
