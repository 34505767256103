import {Component, OnInit} from '@angular/core';
import {AccountService, AlertService, ModalService, PermissionService} from "../_services";
import {TableService} from "../_services/table.service";
import {ViewPerfil} from "../_models/viewPerfil";
import {ColumnType} from "../_models/enums/column-type";
import {Perfil} from "../_models/perfil";
import {ModalExclusao} from "../_models/components/modal-exclusao";
import {PerfilService} from "../_services/perfil.service";
import {MenuItem} from "primeng/api";
import {TipoSituacao, TipoSituacaoList} from "../_models/enums/situacao";
import {User} from "../_models";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css'],
  providers: [
    TableService
  ]
})

export class PerfilComponent implements OnInit {

  constructor(
    public permissionService:PermissionService,
    public table:TableService<ViewPerfil>,
    public modalService: ModalService,
    public perfilService: PerfilService,
    public alertService:AlertService,
    public accountService:AccountService,
    public fb:UntypedFormBuilder)
  {
    this.accountService.user.subscribe(x => this.usuarioLogado = x);
    this.idResponsavelCriacaoAdd=this.usuarioLogado.id;
  }

  nome;
  perfil:Perfil;
  modalExclusao: ModalExclusao;
  rowIndex:number;
  selectedPerfis:ViewPerfil[];
  acoes:MenuItem[];
  clonedPerfis:any[]= [];
  situacoes=TipoSituacaoList
  usuarioLogado:User;

  //Variáveis para adição de registro
  nomePerfilAdd;
  nomeResponsavelCriacaoAdd;
  situacaoAdd= TipoSituacao.Ativo;
  idResponsavelCriacaoAdd;
  uf;
  formFiltroPerfil:UntypedFormGroup

  ngOnInit(): void {
    this.createForm();
    this.getUf(this.accountService.userValue.menu,'SEG05');
    this.createTable();
    this.generateActions();
  }
  generateActions(){
    this.acoes = [
      {label: 'Ativar', icon: 'pi pi-minus-circle', command: (event) => this.ativarPerfil(),visible:this.permissionService.hasPermission(this.uf.codigo,'Incluir')},
      {label: 'Suspender', icon: 'pi pi-times-circle', command: (event) => this.suspenderPerfil(),visible:this.permissionService.hasPermission(this.uf.codigo,'Atualizar')},
      {label: 'Cancelar', icon: 'pi pi-lock', command: () => this.cancelarPerfil(),visible:this.permissionService.hasPermission(this.uf.codigo,'Remover')},
    ];
  }
  filtrar(){
    this.table.reloadData2(this.filtro());
  }
  limparFiltro() {
    this.formFiltroPerfil.reset();
    this.filtrar();
  }
  filtro(){
    return this.formFiltroPerfil.value;
  }
  createTable(){
    this.table.cols = [
      {field:'nome',header:'Nome',width:'10%'},
      {field:'nomeResponsavelCriacao',header:'Responsavel',width:'10%'},
      {field: 'situacao', header: 'Situação', width: '10%', type: ColumnType.Enum, format: 'TipoSituacao'},
    ];
    this.table.setColActionsWidth('5%').withUrlAction2('perfil/listar',this.filtro());
  }
  ativarPerfil(){
    let perfis = this.selectedPerfis.map(x=>x.id);
    this.perfilService.ativarPerfis(perfis).subscribe(
      res=>{
        if(res.success) {
          this.alertService.success(res.messages.join('<br/>'));
          this.filtrar();
        }else{
          this.alertService.error(res.messages.join('<br/>'))
        }
      }
    );
  }
  suspenderPerfil(){
    let perfis = this.selectedPerfis.map(x=>x.id);
    this.perfilService.suspenderPerfis(perfis).subscribe(
      res=>{
        if(res.success) {
          this.alertService.success(res.messages.join('<br/>'));
          this.filtrar();
        }else{
          this.alertService.error(res.messages.join('<br/>'))
        }
      }
    );
  }
  cancelarPerfil(){
    let perfis = this.selectedPerfis.map(x=>x.id);
    this.perfilService.cancelarPerfis(perfis).subscribe(
      res=>{
        if(res.success) {
          this.alertService.success(res.messages.join('<br/>'));
          this.filtrar();
        }else{
          this.alertService.error(res.messages.join('<br/>'))
        }
      }
    );
  }
  incluirPerfil(){
    if(this.table.adding==true) return;
    this.table.adding = true;
    this.table.editing=false;
    this.clonedPerfis=this.table.records;
    let novo=new ViewPerfil();
    novo.idResponsavelCriacao=this.usuarioLogado.id;
    novo.nomeResponsavelCriacao=this.usuarioLogado.firstName;

    this.nomeResponsavelCriacaoAdd=this.usuarioLogado.firstName;

    this.clonedPerfis.unshift(novo);
  }
  onRowEditSave(rowData) {
    var clonedRowData = this.table.clonedData[rowData['id']]
    var perfilDto={
      idPerfil:rowData.id,
      nomePerfil:rowData.nome,
      tipoSituacao: clonedRowData.situacao,
      idResponsavel:rowData.idResponsavelCriacao,
      nomeResponsavel: rowData.nomeResponsavelCriacao
    };
    this.perfilService.salvarPerfil(perfilDto).subscribe(
      res=>{
        if(res.success) {
          this.alertService.success(res.messages.join('<br/>'));
          this.filtrar();
        }else{
          this.alertService.error(res.messages.join('<br/>'))
        }
      }
    );
  }
  onRowAddSave(){
      let perfilDto={
        nomePerfil:this.nomePerfilAdd,
        tipoSituacao: TipoSituacao.Ativo,
        nomeResponsavel:this.nomeResponsavelCriacaoAdd,
        idResponsavel:this.idResponsavelCriacaoAdd,
      };
      this.perfilService.salvarPerfil(perfilDto).subscribe(
        res=>{
          if(res.success) {
            this.alertService.success(res.messages.join('<br/>'));
            this.filtrar();
          }else{
            this.alertService.error(res.messages.join('<br/>'))
          }
        }
      );
    }
  onRowAddCancel(){
    this.nomePerfilAdd='';
    this.nomeResponsavelCriacaoAdd='';
    this.situacaoAdd=TipoSituacao.Ativo;
    this.idResponsavelCriacaoAdd='';

    this.table.adding=false;
    this.table.records.shift();
    this.table.isSubmitting=false;
  }
  preExcluir(model) {
    this.modalExclusao = new ModalExclusao(model.id, 'Perfil');
    this.modalService.abrirModalExclusao();
  }
  excluir($event) {
    this.perfilService.excluirPerfil($event).subscribe(
      res => {
        this.table.reloadData2(this.filtro());
        this.alertService.success(res.messages.join('<br/>'));
      },
      error => {
        this.alertService.error(error)
      }
    );
  }
  getUf(base,uf):any{
    if(base==undefined) return {codigo:'',nome:''};
    let data = base.filhos.find(x=>x.codigo==uf);
    if(data !=undefined){
      this.uf= data;
    }
    base.filhos.forEach(x=>{
      return this.getUf(x, uf);
    });
  }
  createForm(){
    this.formFiltroPerfil=this.fb.group({
      nome:['']
    });
  }

}
