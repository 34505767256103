export class Atalho {
  id: string;
  nome: string;
  descricao: string;
  url: string;
  notificacao: boolean = false;
  tipo: number = null;
  icone: string;
}

interface Categoria {
  name: string;
  code: number;
}

export const CategoriaAtalho: Categoria[] = [
  {name: 'Corporativo', code: 1},
  {name: 'Vendas', code: 2}
]

