export class EuropaMask{
  static telefoneMask(telefone){
    if(telefone.length == 10){
      var regex = /^(\d{2})(\d{4})(\d{4})$/;
      var resultado = telefone.replace(regex, '($1) $2-$3');
      return resultado;
    }
    if(telefone.length == 11){
      var regex = /^(\d{2})(\d{5})(\d{4})$/;
      var resultado = telefone.replace(regex, '($1) $2-$3');
      return resultado;
    }
  }

  static cpfMask(cpf: string): string {
    const cpfDigits = cpf.replace(/\D/g, '');
    return cpfDigits.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  static cnpjMask(cnpj: string): string {
    const cnpjDigits = cnpj.replace(/\D/g, '');
    return cnpjDigits.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
}

