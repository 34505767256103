import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AlertService, PermissionService} from '../../_services';
import {TableService} from '../../_services/table.service';
import {UsuariosService} from '../../_services/usuarios.service';
import {Usuario} from "../../_models/usuario";
import {ColumnType} from "../../_models/enums/column-type";
import {TipoSituacaoMapping} from "../../_models/enums/situacao";

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css'],
  providers: [
    TableService
  ]
})
export class UsuariosComponent implements OnInit {

  formUsuario: UntypedFormGroup;
  usuario: Usuario;
  sistemaSelecionado: any;
  nome: string;
  isEditing: boolean;

  //dropdowns
  situacoes;
  perfis;
  sistemas;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public permissionService: PermissionService,
    private service: UsuariosService,
    public table: TableService<Usuario>,
    private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.createFormUsuario(new Usuario());
    this.carregarSistemas();
    this.generateDropdownOptions();

    this.table.cols = [
      {field: 'nome', header: 'Nome', width: '25%'},
      {field: 'email', header: 'E-mail', width: '20%'},
      {field: 'nomePerfil', header: 'Perfil', width: '15%'},
      {field: 'login', header: 'Login', width: '20%'},
      {field: 'situacao', header: 'Situação', type: ColumnType.Enum, format: 'TipoSituacao', width: '12%'}
    ];

    this.table.setColActionsWidth('10%');
    this.service.usuarioEDIT.subscribe((value) => {
      this.usuario = value;
    });
  }

  createFormUsuario(usuario: Usuario) {
    this.formUsuario = this.formBuilder.group({
      id: [usuario.id],
      nome: [usuario.nome],
      login: [usuario.login],
      email: [usuario.email],
      situacao: [usuario.situacao],
      nomePerfil: [usuario.nomePerfil],
      perfil: [usuario.perfil]
    });

    this.adjustFormValues();
  }

  adjustFormValues() {
    this.formUsuario.controls['situacao'].setValue(
      TipoSituacaoMapping[this.formUsuario.controls['situacao'].value]);

    var perfil = this.formUsuario.controls['perfil'].value;
    if (perfil) {
      this.formUsuario.controls['perfil'].setValue(this.perfis.find(x => x.id == perfil.id))
    }
  }

  filtrar() {
    this.carregar();
  }

  limparFiltro() {
    this.nome = '';
  }

  carregar() {
    this.service.listarUsuarios(this.sistemaSelecionado.codigo, this.nome).subscribe(
      response => {
        this.table.records = response.data.records;
        this.table.loading = false;
        this.table.total = response.data.total;
      }
    );
  }

  generateDropdownOptions() {
    this.situacoes = TipoSituacaoMapping;
  }

  selecionarSistema(sistema?) {
    this.table.records = undefined;
    this.sistemaSelecionado = sistema.value;
    this.limparFiltro();
    this.carregarPerfis('');
    this.carregar()
  }

  carregarSistemas() {
    this.service.listarSistemas().subscribe(
      data => {
        this.sistemas = data.data;
      }
    )
  }

  carregarPerfis(queryPerfil) {
    this.service.listarPerfis(queryPerfil).subscribe(
      data => {
        this.perfis = data.data.records;
      }
    );
  }

  editar(rowData) {
    this.service.setUsuario(rowData);
    this.usuario = rowData;
    this.createFormUsuario(this.usuario)
    this.isEditing = true;
  }

  close() {
    this.isEditing = false;
  }

  save() {
    this.service.alterar(this.formUsuario.value, this.sistemaSelecionado.codigo).subscribe(
      success => {
        this.alertService.success(success.messages[0]);
        this.isEditing = false;
        this.carregar();
      },
      error => {
        this.alertService.error(error)
      }
    )
  }
}
