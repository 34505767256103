import { Component, OnInit } from '@angular/core';
import { PermissionService } from '../../_services';

@Component({
  selector: 'app-escolha-acesso',
  templateUrl: './escolha-acesso.component.html',
  styleUrls: ['./escolha-acesso.component.css']
})
export class EscolhaAcessoComponent implements OnInit {
  uf: string = 'ACS01';

  constructor(public permissionService: PermissionService) { }

  ngOnInit(): void {
  }

}
