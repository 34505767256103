import {SituacaoCliente} from "./enums/situacaoCliente";
import {TipoCliente} from "./enums/tipoCliente";

export class Cliente{
  id:string;
  uuid:string;
  nome:string;
  codigo:string;
  cpf:string;
  cnpj:string;
  tipoCliente:TipoCliente;
  situacao:SituacaoCliente;
  emailResponsavel:string;
  nomeResponsavel:string;
  telefoneResponsavel:string;

  constructor() {
    this.situacao=SituacaoCliente.Ativo;
  }
}
