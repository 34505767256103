import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdministracaoRoutingModule} from './administracao-routing.module';
import {AtalhoComponent} from './atalho/atalho.component';
import {LayoutComponent} from './layout.component';
import {HomeComponent} from './home/home.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NotificacaoComponent} from './notificacao/notificacao.component';
import {TableModule} from 'primeng/table';
import {ColumnRenderPipe} from '../_pipes/column.render.pipe';
import {SegurancaComponent} from './seguranca/seguranca.component';
import {CalendarModule} from "primeng/calendar";
import {PaginatorModule} from "primeng/paginator";
import {ButtonModule} from 'primeng/button';
import {ModalExclusaoComponent} from '../_components/modal-exclusao/modal-exclusao.component';
import {ModalVisualizacaoComponent} from '../_components/modal-visualizacao/modal-visualizacao.component';
import {DropdownIconsComponent} from '../_components/dropdown-icons/dropdown-icons.component';
import {PermissoesComponent} from './permissoes/permissoes.component';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {ListboxModule} from 'primeng/listbox';
import {ParametroComponent} from './parametro/parametro.component';
import {ParametroSistemaComponent} from './parametro-sistema/parametro-sistema.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';

import {PerfilSistemaGrupoAdComponent} from './perfil-sistema-grupo-ad/perfil-sistema-grupo-ad.component';
import {SplitButtonModule} from 'primeng/splitbutton';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {UsuariosComponent} from './usuarios/usuarios.component';
import {RippleModule} from "primeng/ripple";
import {DialogModule} from 'primeng/dialog';
import {InputTextModule} from "primeng/inputtext";


@NgModule({
  declarations: [
    AtalhoComponent,
    LayoutComponent,
    HomeComponent,
    NotificacaoComponent,
    SegurancaComponent,
    ModalExclusaoComponent,
    ModalVisualizacaoComponent,
    DropdownIconsComponent,
    PermissoesComponent,
    ParametroComponent,
    ParametroSistemaComponent,
    UsuariosComponent,
    PerfilSistemaGrupoAdComponent,
    ColumnRenderPipe
  ],
  exports: [
    ModalExclusaoComponent,
    ColumnRenderPipe
  ],
  imports: [
    FormsModule,
    CommonModule,
    AdministracaoRoutingModule,
    ReactiveFormsModule,
    TableModule,
    CalendarModule,
    PaginatorModule,
    ButtonModule,
    ListboxModule,
    CheckboxModule,
    DropdownModule,
    ConfirmDialogModule,
    DialogModule,
    RippleModule,
    InputTextModule,
    SplitButtonModule,
    ProgressSpinnerModule,
    AutoCompleteModule
  ]
})
export class AdministracaoModule {
}
