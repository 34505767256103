import {AfterViewInit, Component} from '@angular/core';
import { AccountService, PermissionService } from './_services';
import { User } from './_models';
import { Router } from "@angular/router";
import { PrimeNGConfig } from 'primeng/api';
import { PRIMENG_PT_BR } from './_resources/primeng-locale';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent implements AfterViewInit{
  user: User;

  constructor(private accountService: AccountService,
    private router: Router, private config: PrimeNGConfig,
    public permissionService: PermissionService,)
  {
    this.accountService.user.subscribe(x => this.user = x);
  }

  ngOnInit() {
    this.config.setTranslation(PRIMENG_PT_BR);

    document.getElementById('main-container').addEventListener('click', (e) => {
      const target = e.target as HTMLElement;
      this.closeMenu(target);
    });
  }

  ngAfterViewInit() {
    document.querySelectorAll('.menu-popup a').forEach(e => e.addEventListener('click', function (){
      document.getElementById('menu-popup').classList.toggle('d-none');
    }))
  }

  closeMenu(target: HTMLElement) {
    const classList = target.classList;
    const menu = document.getElementById('menu-popup');
    if (menu && classList.contains('menu-opener')) {
      menu.classList.toggle('d-none');
    } else if (menu && !classList.contains('menu-popup') && !classList.contains('menu-item')) {
      menu.classList.add('d-none');
    }
  }

  isAdm() {
    return this.router.url.includes('/administracao/');
  }
}
