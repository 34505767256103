<div class="disabled-area" *ngIf="this.modalService.visualizeVisible">
  <div class="card card-visualize">
    <a class="close" (click)="close()">
      <span></span>
      <span></span>
    </a>
    <div class="content">
      <span class="title">{{model.titulo}}</span>
      <span *ngFor="let data of model.content">
        <span class="text">{{data}}</span>
      </span>
    </div>
  </div>
</div>
 


