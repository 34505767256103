import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import icones from "../../_models/json/icones.json";

@Component({
  selector: 'app-dropdown-icons',
  templateUrl: './dropdown-icons.component.html',
  styleUrls: ['./dropdown-icons.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropdownIconsComponent
    }
  ]
})
export class DropdownIconsComponent implements ControlValueAccessor {
  @Input() icons = icones;
  optionSelected: boolean = false;
  iconsDrodownOpened = false;
  selectedIcon: string;
  onChange = (icon) => { };
  onTouched = () => { };
  touched = false;
  disabled = false;

  constructor(private dom: DomSanitizer) { }

  toggleIconDropdown() {
    this.iconsDrodownOpened = !this.iconsDrodownOpened;
    if (!this.iconsDrodownOpened) {
      this.markAsTouched();
    }
  }

  toggleIcons() {
    this.optionSelected = !this.optionSelected;
  }

  selectIcon(base64) {
    this.iconsDrodownOpened = false;
    this.selectedIcon = base64;
    this.onChange(this.selectedIcon);
  }

  writeValue(icon: string) {
    this.selectedIcon = icon;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
