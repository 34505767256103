import { Cliente } from "./cliente";
import {TipoSituacao} from "./enums/situacao";
import {Perfil} from "./perfil";

export class Usuario {
  id: string;
  nome: string;
  login: string;
  situacao: TipoSituacao;
  email: string;
  nomePerfil: string;
  perfilId: number;
  perfil: Perfil;
  sistema: string;

  //Extras HUBHAWK
  uuid: string;
  telefone: string;
  salt: string;
  cliente: Cliente;
}
