<div class="container-fluid">
  <div class="row justify-content-between no-gutters">
    <div class="col-4"><h2>{{uf.nome}}</h2></div>
    <div class="col-4" class="flexEnd">
      <button pButton pRipple label="Incluir" (click)="incluirPerfil()" icon="pi pi-plus" class="p-button p-button-success"
              *ngIf="permissionService.hasPermission(uf.codigo,'Incluir')"></button>
    </div>
  </div>
  <form [formGroup]="formFiltroPerfil">
    <div class="row">
      <div class="col-5">
        <span class="p-float-label">
          <input id="float-input" pInputText formControlName="nome" type="text" class="form-control">
          <label for="float-input">Nome</label>
        </span>
      </div>
      <div class="col-7 flexEnd">
        <button pButton pRipple icon="pi pi-search" type="button"
                (click)="filtrar()" label="Filtrar" class="p-button-primary"
                *ngIf="permissionService.hasPermission(uf.codigo,'Visualizar')">
        </button>
        <button pButton pRipple icon="pi pi-filter" type="button" style="margin-left: 1%;margin-right: 1%"
                (click)="limparFiltro()" label="Limpar" class="p-button-secondary"
                *ngIf="permissionService.hasPermission(uf.codigo,'Visualizar')">
        </button>
        <p-splitButton label="Ações" icon="pi pi-list" [model]="acoes" class="splittButtonOptions"
                       *ngIf="permissionService.hasPermission(uf.codigo,'Atualizar')"></p-splitButton>
      </div>
    </div>
  </form>
  <br/>
  <div class="row">
    <p-table selectionMode="multiple" editMode="row" [(selection)]="selectedPerfis"
             dataKey="id"
             class="col-12" styleClass="p-datatable-gridlines p-datatable-striped"
             [(first)]="this.table.request.start"
             [lazy]="true"
             [columns]="table.cols"
             [value]="table.records"
             (onLazyLoad)="this.table.init2($event)"
             sortField="nome" [sortOrder]="-1"
             [loading]="table.loading"
             [showCurrentPageReport]="true"
             [paginator]="true"
             [rows]=table.rowPerPage[0]
             [rowsPerPageOptions]=table.rowPerPage
             [totalRecords]="table.total">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th [ngStyle]="{'width': table.colActionsWidth}">Ações</th>
          <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" [ngStyle]="{'width': col.width}">
            <p-sortIcon field="{{col.field}}"></p-sortIcon>
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns" let-editing="editing">
        <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [pEditableRow]="rowData">
          <td class="text-center col-actions">
            <div class="flex align-items-center justify-content-center gap-2">
              <!--ADIÇÃO-->
              <button *ngIf="table.adding && rowIndex != 0 && !editing && permissionService.hasPermission(uf.codigo,'Incluir')" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="table.onRowEditInit(rowData)" class="p-button-rounded p-button-text"></button>

              <!--PÓS ADIÇÃO-->
              <button *ngIf="table.adding && rowIndex == 0" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowAddSave()" class="p-button-rounded p-button-text p-button-success mr-2"></button>
              <button *ngIf="table.adding && rowIndex == 0" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowAddCancel()" class="p-button-rounded p-button-text p-button-danger"></button>

              <!--EDITAR e EXCLUIR (PADRÃO)-->
              <button *ngIf="!editing && !table.adding && permissionService.hasPermission(uf.codigo,'Atualizar')" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="table.onRowEditInit(rowData)" class="p-button-rounded p-button-text"></button>
              <button *ngIf="!editing && !table.adding && permissionService.hasPermission(uf.codigo,'Remover')" pButton pRipple type="button" icon="pi pi-trash" (click)="preExcluir(rowData)" class="p-button-rounded p-button-text"></button>
              <button *ngIf="!editing && table.adding && rowIndex != 0 && permissionService.hasPermission(uf.codigo,'Remover')" pButton pRipple type="button" icon="pi pi-trash" (click)="preExcluir(rowData)" class="p-button-rounded p-button-text"></button>

              <!--PÓS EDITAR-->
              <button *ngIf="editing && permissionService.hasPermission(uf.codigo,'Atualizar')" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(rowData)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
              <button *ngIf="editing && permissionService.hasPermission(uf.codigo,'Atualizar')" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="table.onRowEditCancel(rowData, rowIndex)" class="p-button-rounded p-button-text p-button-danger"></button>
            </div>
          </td>
          <td *ngFor="let col of columns" class="text-center {{col.class}}" style="padding: 5px;">
            <!--ADIÇÃO-->
            <div *ngIf="col.field == 'nome'">
              <input [ngClass]="{ 'invalid': table.isSubmitting && !nomePerfilAdd}" *ngIf="table.adding && rowIndex == 0"  required class="form-control" type="text" [(ngModel)]=nomePerfilAdd>
            </div>
            <div *ngIf="col.field == 'nomeResponsavelCriacao'">
              <input *ngIf="table.adding && rowIndex == 0" pInputText class="form-control" type="text" [(ngModel)]="nomeResponsavelCriacaoAdd" [disabled]="true">
            </div>
            <div *ngIf="col.field == 'situacao'">
              <p-dropdown *ngIf="table.adding && rowIndex == 0" [options]="situacoes" optionLabel="name" [(ngModel)]="situacaoAdd" [disabled]="true" [autoDisplayFirst]="true"></p-dropdown>
            </div>

            <!--EDIÇÃO-->
            <p-cellEditor *ngIf="col.field == 'nome'">
              <ng-template pTemplate="input">
                <input pInputText required   class="form-control" type="text"  [(ngModel)]="rowData[col.field]">
              </ng-template>
              <ng-template pTemplate="output">
                <span *ngIf="!table.adding" [innerHtml]="rowData[col.field] | columnRender : col.type:col.format"></span>
              </ng-template>
            </p-cellEditor>

            <p-cellEditor *ngIf="col.field == 'nomeResponsavelCriacao'">
              <ng-template pTemplate="input">
                <input pInputText class="form-control" type="text" [disabled]="true" [(ngModel)]="rowData[col.field]">
              </ng-template>
              <ng-template pTemplate="output">
                <span *ngIf="!table.adding" [innerHtml]="rowData[col.field] | columnRender : col.type:col.format"></span>
              </ng-template>
            </p-cellEditor>

            <p-cellEditor *ngIf="col.field == 'situacao'">
              <ng-template pTemplate="input">
                <p-dropdown [options]="situacoes" optionLabel="name" [disabled]="true" [(ngModel)]="rowData[col.field]"></p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                <span *ngIf="!table.adding" [innerHtml]="rowData[col.field] | columnRender : col.type:col.format"></span>
              </ng-template>
            </p-cellEditor>
            <span *ngIf="table.adding && rowIndex!=0" [innerHtml]="rowData[col.field] | columnRender : col.type:col.format"></span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <app-modal-exclusao [model]="modalExclusao" (deleteEvent)="excluir($event)"></app-modal-exclusao>

</div>
