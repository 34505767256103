<div class="container-fluid">
  <div class="mb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/">Portal</a></li>
        <li class="breadcrumb-item"><a routerLink="/administracao">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Usuarios</li>
      </ol>
    </nav>
  </div>
  <div class="row justify-content-between titulo">
    <h2>Usuários</h2>
    <p-dropdown [options]="sistemas" class="dropdownSistemas" [(ngModel)]="sistemaSelecionado"
                (onChange)="selecionarSistema($event)" placeholder="Selecione o Sistema" optionLabel="nome"
                *ngIf="permissionService.hasPermission('SEG02','Listar')"></p-dropdown>
  </div>
  <div class="row">
    <div *ngIf="table.records != undefined">
      <div class="header row">
        <div class="col-3">
          <input (keydown.enter)='filtrar()' [(ngModel)]="nome" type="text" class="form-control" placeholder="Nome">
        </div>
        <div class="col-2 fixButtons">
          <button style="margin-left: 3%; height: 35px;" pButton pRipple icon="pi pi-search"
                  type="button" (click)="filtrar()"
                  label="Filtrar" class="p-button-danger">
          </button>
          <button style="margin-left: 3%; height: 35px;" pButton pRipple icon="pi pi-filter"
                  type="button" (click)="limparFiltro()"
                  label="Limpar" class="p-button-secondary">
          </button>
        </div>
      </div>
      <br>
      <p-table [columns]="table.cols" [value]="table.records" (onLazyLoad)="this.table.init($event)"
               [paginator]="true" [rows]="10" [totalRecords]="table.total" sortField="nome" [sortOrder]="1"
               [loading]="table.loading" [showCurrentPageReport]="true">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="text-align:center" [ngStyle]="{'width': table.colActionsWidth}">Ações</th>
            <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" [ngStyle]="{'width': col.width}">
              <p-sortIcon field="{{col.field}}"></p-sortIcon>
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td class="text-center col-actions">
              <button pButton pRipple icon="pi pi-pencil" title="Editar"
                      (click)="editar(rowData)"
                      class="p-element p-ripple p-button-rounded p-button-primary p-button-text p-button p-component p-button-icon-only m-r-2">
              </button>
            </td>
            <td *ngFor="let col of columns" class="{{col.class}}"
                [innerHtml]="rowData[col.field] | columnRender : col.type:col.format"></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>


<p-dialog header="Editar Usuario" [(visible)]="isEditing" [modal]="true" [style]="{width: '60vw'}" [draggable]="false"
          [resizable]="false">
  <div class="container">
    <br>
    <form style="min-height:5vw" [formGroup]="formUsuario">
      <div class="form-group mt-2">
        <div class="content ">
          <div class="row">
            <input formControlName="id" name="id" type="hidden">
            <div class="col">
              <label>Nome</label>
              <br>
              <input formControlName="nome" maxlength="60" pInputText style="width:100%;"
                     type="text"/>
            </div>
            <div disabled class="col">
              <label>Login</label>
              <br>
              <input readonly formControlName="login" maxlength="60" pInputText style="width:100%;"
                     type="text"/>
            </div>
            <div class="col-3">
              <label>Situação </label>
              <br>
              <p-dropdown formControlName="situacao" [options]="situacoes"
                          optionLabel="nome"></p-dropdown>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-5">
              <label>E-mail</label>
              <br>
              <input readonly formControlName="email" maxlength="60" pInputText style="width:100%;"
                     type="text"/>
            </div>
            <div class="col-5">
              <label>Perfil</label>
              <br>
              <p-dropdown [options]="perfis" formControlName="perfil"
                          placeholder="Selecione o Perfil" optionLabel="nome" appendTo="body"
              ></p-dropdown>
            </div>
          </div>
          <br>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-2">
          <div style="width:100%;">
            <button (click)="save()" class="p-button-success" icon="pi pi-save" label="Salvar" pButton
                    type="button"></button>
          </div>
        </div>
      </div>
      <br>
    </form>
  </div>
</p-dialog>
